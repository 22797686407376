import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { catchError, map } from 'rxjs';

@Component({
  selector: 'app-echo',
  templateUrl: './echo.component.html',
  styleUrls: ['./echo.component.sass']
})
export class EchoComponent implements OnInit {
  public response : object;

  constructor(
    private httpclient : HttpClient
  ) {}

  ngOnInit(): void {
    this.httpclient.get<object>("https://sf1-dev-core-apim-wrd4.azure-api.net/echo/resource?param1=sample").subscribe(
      data => {
        console.log(data);
          this.response = <object>data;
      },
      error => console.log('error',error)
      
    );
  }

}
