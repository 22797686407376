<header
  class="site-header"
  [ngClass]="{ 'site-header__center': !loginDisplay }"
>
  <div class="container site-heade__container">
    <div class="site-header__logo">
      <img
        src="https://shepherdsfriendly.blob.core.windows.net/wp-media/2020/08/logo.svg"
        alt="shepherds-friendly-logo"
      />
    </div>
    <div class="site-header__controls">
      <!-- <button class="btn btn-link" *ngIf="!loginDisplay" (click)="login()">
                <span>Login</span>
            </button> -->

      <button class="btn btn-link" *ngIf="loginDisplay" (click)="logout()">
        Logout
      </button>

      <button class="btn btn-link" [routerLink]="['/']" *ngIf="loginDisplay">
        Dashboard
      </button>
    </div>
  </div>
</header>
