export class PostcodeSearchModel {
	Id: string;
	StreetAddress: string;
	Place: string;

	constructor(id: string, streetAddress: string, place: string) {
		this.Id = id;
		this.StreetAddress = streetAddress;
		this.Place = place;
	}
}
