import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDynamicFormfield } from '../../models/dynamic-formfield.interface';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionTextbox } from '../../models/questions/question-textbox.model';

@Component({
	selector: 'app-dynamic-textbox',
	templateUrl: 'dynamic-textbox.component.html',
	styleUrls: ['dynamic-textbox.component.css']
})
export class DynamicTextboxComponent implements IDynamicFormfield {
	@Input() question: QuestionTextbox;
	@Input() form: CustomFormGroup;
	@Output() eventHandler = new EventEmitter();
}
