<div [formGroup]="form" [attr.id]="'form-' + question.key">
  <div formGroupName="{{ question.key }}">
    <div class="marginBtm15">
      <div>
        <p class="directdebit__text">
          In order to pay the premiums for your plan you will need to set up a
          monthly Direct Debit.
        </p>

        <p class="directdebit__text">
          Please complete the Direct Debit instruction below, and this will be
          used to instruct your bank or building society to pay the Direct Debit
          to us. In order to do this the account must be in your name, or must
          be a joint account that you have authorisation to make payments from.
          We cannot accept business account details.
        </p>
      </div>
    </div>

    <div class="marginBtm15" style="display: flex;">
      <div>
        <label for="ddNameOfAccountHolder">Account holder's name</label>
      </div>
      <div>
        <input
          id="ddNameOfAccountHolder"
          name="ddNameOfAccountHolder"
          formControlName="ddNameOfAccountHolder"
          type="text"
          class="form__input"
          autocomplete="off"
        />
      </div>

      <div>
        <app-dynamic-validation
          [form]="form"
          [key]="'BankDetails.ddNameOfAccountHolder'"
          [label]="'Account holder\'s name'"
        ></app-dynamic-validation>
      </div>
    </div>

    <div class="marginBtm15">
      <div>
        <label for="ddSortCode">Sort code</label>
      </div>
      <div>
        <input
          id="ddSortCode"
          name="ddSortCode"
          formControlName="ddSortCode"
          type="text"
          class="form__input"
          [mask]="sortMask"
          [dropSpecialCharacters]="false"
          [showMaskTyped]="true"
          autocomplete="off"
        />
      </div>
      <div>
        <app-dynamic-validation
          [form]="form"
          [key]="'BankDetails.ddSortCode'"
          [label]="'Sort code'"
        ></app-dynamic-validation>
      </div>
    </div>

    <div class="marginBtm30">
      <div>
        <label for="ddAccountNumber">Account number</label
        >
      </div>
      <div>
        <input
          id="ddAccountNumber"
          name="ddAccountNumber"
          formControlName="ddAccountNumber"
          type="text"
          [mask]="accountNumberMask"
          [dropSpecialCharacters]="false"
          [showMaskTyped]="true"
          class="form__input"
          autocomplete="off"
        />
      </div>
      <div>
        <app-dynamic-validation
          [form]="form"
          [key]="'BankDetails.ddAccountNumber'"
          [label]="'Account number'"
        ></app-dynamic-validation>
      </div>
    </div>

    <div class="marginBtm30">
      <div>
        <label>Direct Debit allowed from account?</label
        >
      </div>
      <div class="NW2-radioBtnsContainer">
        <div class="NW20-radioBtn">
          <input
            id="ddAllowedFromAccount"
            name="ddAllowedFromAccount"
            formControlName="ddAllowedFromAccount"
            type="radio"
            value="true"
            autocomplete="off"
          />
          <label for="ddAllowedFromAccount">Yes</label>
        </div>
        <div class="NW20-radioBtn">
          <input
            id="ddAllowedFromAccount"
            name="ddAllowedFromAccount"
            formControlName="ddAllowedFromAccount"
            type="radio"
            value="false"
            autocomplete="off"
          />
          <label for="ddAllowedFromAccount">No</label>
        </div>
      </div>
      <div>
        <app-dynamic-validation
          [form]="form"
          [key]="'BankDetails.ddAllowedFromAccount'"
          [label]="'Direct Debit allowed from account'"
        ></app-dynamic-validation>
      </div>
    </div>

    <div class="marginBtm30">
      <div>
        <label>Bank account held in payers name?</label
        >
      </div>
      <div class="NW2-radioBtnsContainer">
        <div class="NW20-radioBtn">
          <input
            id="ddBankAccountHeldInPayersName"
            name="ddBankAccountHeldInPayersName"
            formControlName="ddBankAccountHeldInPayersName"
            type="radio"
            value="true"
            autocomplete="off"
          />
          <label for="ddBankAccountHeldInPayersName">Yes</label>
        </div>
        <div class="NW20-radioBtn">
          <input
            id="ddBankAccountHeldInPayersName"
            name="ddBankAccountHeldInPayersName"
            formControlName="ddBankAccountHeldInPayersName"
            type="radio"
            value="false"
            autocomplete="off"
          />
          <label for="ddBankAccountHeldInPayersName">No</label>
        </div>
      </div>
      <div>
        <app-dynamic-validation
          [form]="form"
          [key]="'BankDetails.ddBankAccountHeldInPayersName'"
          [label]="'Bank account held in payers name'"
        ></app-dynamic-validation>
      </div>
    </div>

    <div class="marginBtm15">
      <div class="NW20-tooltip-label-container">
        <label>Direct Debit collection date?</label>
        <app-dynamic-tooltip
          [key]="'BankDetails.ddCollectionDate'"
          [tooltip]="ddCollectionTooltip"
        ></app-dynamic-tooltip>
      </div>
      <div class="NW2-radioBtnsContainer">
        <div class="NW20-radioBtnsSmall">
          <input
            id="ddCollectionDate"
            name="ddCollectionDate"
            formControlName="ddCollectionDate"
            type="radio"
            value="1"
            autocomplete="off"
          />
          <label for="ddCollectionDate">1st</label>
        </div>
        <div class="NW20-radioBtnsSmall">
          <input
            id="ddCollectionDate"
            name="ddCollectionDate"
            formControlName="ddCollectionDate"
            type="radio"
            value="8"
            autocomplete="off"
          />
          <label for="ddCollectionDate">8th</label>
        </div>
        <div class="NW20-radioBtnsSmall">
          <input
            id="ddCollectionDate"
            name="ddCollectionDate"
            formControlName="ddCollectionDate"
            type="radio"
            value="16"
            autocomplete="off"
          />
          <label for="ddCollectionDate">16th</label>
        </div>
        <div class="NW20-radioBtnsSmall">
          <input
            id="ddCollectionDate"
            name="ddCollectionDate"
            formControlName="ddCollectionDate"
            type="radio"
            value="24"
            autocomplete="off"
          />
          <label for="ddCollectionDate">24th</label>
        </div>
      </div>

      <div>
        <app-dynamic-validation
          [form]="form"
          [key]="'BankDetails.ddCollectionDate'"
          [tooltip]="ddCollectionTooltip"
          [label]="'Direct Debit collection date'"
        ></app-dynamic-validation>
      </div>
    </div>

    <div class="marginBtm15">
      <div>
        <img
          id="ddlogo"
          src="https://shepherdsfriendly.blob.core.windows.net/image-media/ddlogo.png"
          alt="Direct Debit"
          class="img-responsive marginBtm30 NW20-dbImg"
        />

        <p class="directdebit__text">
          This Guarantee is offered by all banks and building societies that
          accept instructions to pay Direct Debits.
        </p>

        <p class="directdebit__text">
          If there are any changes to the amount, date or frequency of your
          Direct Debit, The Shepherds Friendly Society Limited will notify you 5
          working days in advance of your account being debited or as otherwise
          agreed. If you request The Shepherds Friendly Society Limited to
          collect a payment, confirmation of the amount and date will be given
          to you at the time of the request.
        </p>

        <p class="directdebit__text">
          If an error is made in the payment of your Direct Debit, by The
          Shepherds Friendly Society Limited or your Bank or Building Society
          you are entitled to a full and immediate refund of the amount paid
          from your Bank or Building Society.
        </p>

        <p class="directdebit__text">
          If you receive a refund you are not entitled to, you must pay it back
          when The Shepherds Friendly Society Limited asks you to.
        </p>

        <p class="directdebit__text">
          You can cancel a Direct Debit at any time by simply contacting your
          Bank or Building Society. Written confirmation may be required. Please
          also notify us.
        </p>
      </div>
    </div>
  </div>
</div>
