<div class="wrapper">
  <app-navigation></app-navigation>
  <app-loading-spinner></app-loading-spinner>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>
<footer class="footer">
  <div class="footer__row">
    <div class="footer__top-links">
      <div class="footer__social">
        <ul class="footer__social-list">
          <li class="footer__social-item">
            <a
              href="https://www.facebook.com/shepherdsfriendly/"
              aria-label="Social item"
              target="_blank"
            >
              <i class="fab fa-facebook" aria-hidden="true"></i>
            </a>
          </li>

          <li class="footer__social-item">
            <a
              href="https://twitter.com/shepherdsfs"
              aria-label="Social item"
              target="_blank"
              ><i class="fab fa-twitter" aria-hidden="true"></i
            ></a>
          </li>

          <li class="footer__social-item">
            <a
              href="https://www.linkedin.com/company/shepherds-friendly-society/"
              aria-label="Social item"
              target="_blank"
              ><i class="fab fa-linkedin" aria-hidden="true"></i
            ></a>
          </li>

          <li class="footer__social-item">
            <a
              href="https://www.youtube.com/channel/UCuukjaXIW6KG71qbshSVSKQ"
              aria-label="Social item"
              target="_blank"
              ><i class="fab fa-youtube" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
        <div class="footer__info">
          <p class="footer__tel-number">
            <span> T. </span>
            0800 526 249
          </p>
          <p class="footer__email">
            <span> E. </span>
            members@shepherdsfriendly.co.uk
          </p>
        </div>
        <div class="footer__help">
          <a href="https://www.shepherdsfriendly.co.uk/help-and-support/"
            ><span>Get in touch</span></a
          >
        </div>
        <div class="footer__company-name">
          <span>© The Shepherds Friendly Society Limited 2024</span>
        </div>
      </div>
    </div>
    <div class="footer__nav">
      <ul class="footer__nav-list">
        <li class="footer__nav-list-item">
          <a href="#" class="footer__nav-list-icon">Our products</a>
          <ul class="footer__nav-submenu">
            <li>
              <a
                href="https://www.shepherdsfriendly.co.uk/stocks-and-shares-isa/"
                >Stocks &amp; Shares ISA</a
              >
            </li>
            <li>
              <a
                href="https://www.shepherdsfriendly.co.uk/over-50s-life-insurance/"
                >Over 50s Life Insurance</a
              >
            </li>
            <li>
              <a href="https://www.shepherdsfriendly.co.uk/junior-isa/"
                >Junior ISA</a
              >
            </li>
            <li>
              <a
                href="https://www.shepherdsfriendly.co.uk/5-year-fixed-rate-bond/"
                >5 Year Fixed Rate Bond</a
              >
            </li>
            <li>
              <a href="https://www.shepherdsfriendly.co.uk/income-protection/"
                >Income Protection Insurance</a
              >
            </li>
          </ul>
        </li>
        <li class="footer__nav-list-item">
          <a href="#" class="footer__nav-list-icon">Company</a>
          <ul class="footer__nav-submenu">
            <li>
              <a href="https://www.shepherdsfriendly.co.uk/help-and-support/"
                >Help &amp; support</a
              >
            </li>
            <li>
              <a href="https://www.shepherdsfriendly.co.uk/about/careers/"
                >Careers</a
              >
            </li>
          </ul>
        </li>
        <li class="footer__nav-list-item">
          <a href="#" class="footer__nav-list-icon">Important information</a>
          <ul class="footer__nav-submenu">
            <li>
              <a href="https://www.shepherdsfriendly.co.uk/about/consumer-duty/"
                >Consumer Duty</a
              >
            </li>
            <li>
              <a
                rel="privacy-policy"
                href="https://www.shepherdsfriendly.co.uk/privacy-policy/"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a href="https://www.shepherdsfriendly.co.uk/cookie-policy/"
                >Cookie policy</a
              >
            </li>
            <li>
              <a href="https://www.shepherdsfriendly.co.uk/modern-slavery/"
                >Modern slavery</a
              >
            </li>
            <li>
              <a
                href="https://www.shepherdsfriendly.co.uk/mutual-diversity-alliance/"
                >Mutual Diversity Alliance</a
              >
            </li>
            <li>
              <a href="https://www.shepherdsfriendly.co.uk/sitemap/">Sitemap</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="footer__copyright">
    <div class="footer__company-name-mobile">
      <span>© The Shepherds Friendly Society Limited 2024</span>
    </div>
    <p>
      Shepherds Friendly is a trading name of The Shepherds Friendly Society
      Limited which is an incorporated Friendly Society under the 1992 Friendly
      Societies Act No. 240F. Authorised by the Prudential Regulation Authority
      and regulated by the Financial Conduct Authority and the Prudential
      Regulation Authority. FS Registration Number 109997.
    </p>
    <p>
      When you take out an investment product with us your capital is at risk
      and you may get back less than you have put in. All references to taxation
      are to UK taxation and are based on Shepherds Friendly Society’s
      understanding of current legislation and H M Revenue and Customs practice
      which may change in the future. For our With Profits plans investment
      growth is by means of bonuses, the amount of which cannot be guaranteed
      throughout the term of the contract.
    </p>
  </div>
</footer>
