<p>dynamic-form works!</p>


<label>a box</label>
<input type="text" />    

<label for="name">Name: </label>
<input id="name" type="text" [formControl]="name">

<p>Value: {{ name.value }}</p>

<button type="button" (click)="updateName()">Update Name</button>


<form [formGroup]="profileForm" (ngSubmit)="onSubmit()">

    <label for="first-name">First Name: </label>
    <input id="first-name" type="text" formControlName="firstName">
  
    <label for="last-name">Last Name: </label>
    <input id="last-name" type="text" formControlName="lastName">
    <div formGroupName="address">
        <h2>Address</h2>
      
        <label for="street">Street: </label>
        <input id="street" type="text" formControlName="street">
      
        <label for="city">City: </label>
        <input id="city" type="text" formControlName="city">
      
        <label for="state">State: </label>
        <input id="state" type="text" formControlName="state">
      
        <label for="zip">Zip Code: </label>
        <input id="zip" type="text" formControlName="zip">
      </div>

      <div formArrayName="aliases">
        <h2>Aliases</h2>
        <button type="button" (click)="addAlias()">+ Add another alias</button>
      
        <div *ngFor="let alias of aliases.controls; let i=index">
          <!-- The repeated alias template -->
          <label for="alias-{{ i }}">Alias:</label>
          <input id="alias-{{ i }}" type="text" [formControlName]="i">
        </div>
      </div>
  </form>
  <button type="button" (click)="updateProfile()">Update Profile</button>
  <p>Complete the form to enable button.</p>
  <p>Form Value: {{ profileForm.value }}</p>
  <p>Form Status: {{ profileForm.status }}</p>
<button type="submit" [disabled]="!profileForm.valid">Submit</button>