import { Component, Input } from '@angular/core';
import { DeathClaim } from 'src/app/models/death-claim.interface';

@Component({
  selector: 'app-death-claim-overview',
  templateUrl: './death-claim-overview.component.html',
  styleUrls: ['./death-claim-overview.component.sass']
})
export class DeathClaimOverviewComponent {
  @Input() deathClaim: DeathClaim;

  public HasBeenSubmitted(){
    return this.deathClaim.latestStage?.toLowerCase() != "unsubmitted";
  }  
}