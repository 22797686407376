import { Injectable } from '@angular/core';

@Injectable()
export class AppConstantsService {
	public Server: string;
	uat = false;
	updatePasswordCallbackUri = `${document.location.origin}/app/authentication/update-password`;

	constructor() {
		this.Server = 'https://api.shepherdsfriendly.co.uk/';
	}

	isLocal() {
		return document.location.hostname === 'localhost';
	}

	getDobMask() {
		return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
	}
}
