import { Component, OnInit, Input } from '@angular/core';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionInformation } from '../../models/questions/question-information.model';
import { MultiTextModel } from '../../models/form/multi-text.model';

@Component({
  selector: 'app-dynamic-information',
  templateUrl: './dynamic-information.component.html',
  styleUrls: ['./dynamic-information.component.css']
})
export class DynamicInformationComponent implements OnInit {
  @Input() question: QuestionInformation;
  @Input() form: CustomFormGroup;

  multitext: MultiTextModel[];

  ngOnInit() {
		// Remove any unnecessary whitespace from indenting in C# - apart from carriage returns
		if (this.question.value) {
			this.question.value = this.question.value.replace(/((?![\r\n])\s+){2,}/g, '');
		}

		this.multitext = this.question.multipleValues as MultiTextModel[];
  }
}