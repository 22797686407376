<div [formGroup]="form" [ngSwitch]="question.type" [attr.id]="'form-' + question.key">
	<!-- Hidden Input -->
	<input *ngSwitchCase="'hidden'" [attr.id]="question.key" type="hidden" class="form__input" [formControlName]="question.key"
	    autocomplete="off" />

	<!-- Readonly Input -->
	<input *ngSwitchCase="'readonly'" [attr.id]="question.key" [attr.type]="question.type" class="form__input"
	    [formControlName]="question.key" readonly="readonly" autocomplete="off" />

	<!-- Datetime Input -->
	<input *ngSwitchCase="'datetime'" [attr.id]="question.key" type="text" class="form__input" 
		[mask]="question.mask[0]" [showMaskTyped]="true" [dropSpecialCharacters]="false"
	    [formControlName]="question.key" autocomplete="off" />

	<!-- Textarea Input -->
	<textarea *ngSwitchCase="'textarea'" [attr.id]="question.key" [attr.type]="question.type" class="form__input resize"
	    [formControlName]="question.key" autocomplete="off"></textarea>

	<!-- Default Input -->
	<input *ngSwitchDefault [attr.id]="question.key" [attr.type]="question.type" class="form__input" [formControlName]="question.key"
		[mask]="question.mask[0]" [showMaskTyped]="true" [dropSpecialCharacters]="false" autocomplete="off" />

	<!-- Currency Input -->
	<ng-container *ngSwitchCase="'currency'">
		<div class="NW20-input-group">
			<span class="NW20-input-group-addon">£</span>
			<input [attr.id]="question.key" type="number" class="appFormCurrencyInput NW20-form-control" [formControlName]="question.key"
				[mask]="question.mask[0]" [showMaskTyped]="true" [dropSpecialCharacters]="false" autocomplete="off" />
		</div>
	</ng-container>
</div>