<ng-container *ngIf="(serverError$ | async) as serverError; else clientError">
  <div [attr.id]="'error-' + key" class="errorMessage NW20-errorIsaMessage">
    <p class="errorMessageContent">
      {{ serverError }}
    </p>
  </div>
</ng-container>

<ng-template #clientError>
  <ng-container *ngIf="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched)">
      <div [attr.id]="'error-' + key" class="errorMessage NW20-errorIsaMessage">
        <p *ngFor="let key of getErrorListByKeys(control.errors)" class="errorMessageContent">
          <ng-container [ngSwitch]="key">
            <ng-container *ngSwitchCase="'required'">{{label}} is required</ng-container>
            <ng-container *ngSwitchCase="'minlength'">{{label}} needs to be greater than {{control.errors?.['minlength'].requiredLength - 1}}</ng-container>
            <ng-container *ngSwitchCase="'maxlength'">{{label}} needs to be less than {{control.errors?.['maxlength'].requiredLength + 1}}</ng-container>
            <ng-container *ngSwitchCase="'min'">{{label}} needs to be greater than {{control.errors?.['min'].requiredLength - 1}}</ng-container>
            <ng-container *ngSwitchCase="'max'">{{label}} needs to be less than {{control.errors?.['max'].requiredLength + 1}}</ng-container>
            <ng-container *ngSwitchCase="'pattern'">{{label}} is not in the correct format</ng-container>
            <ng-container *ngSwitchCase="'email'">{{label}} is not a valid email address</ng-container>
            <ng-container *ngSwitchCase="'requiredtrue'">{{label}} needs to be selected</ng-container>
            <ng-container *ngSwitchCase="'mask'">{{label}} is not in the correct format</ng-container>
            <ng-container *ngSwitchCase="'validDate'">{{label}} is not a valid date</ng-container>
            <ng-container *ngSwitchCase="'notFutureDate'">{{label}} should not be a future date</ng-container>
            <ng-container *ngSwitchCase="'futureDate'">{{label}} should be a future date</ng-container>
            <ng-container *ngSwitchCase="'validSurvivingMember'">{{label}} should be at least one other family member or No</ng-container>
            
            <ng-container *ngSwitchDefault>{{label}} is {{key}}</ng-container>

            
          </ng-container>
        </p>
      </div>
    </div>
  </ng-container>
</ng-template>


