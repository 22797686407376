import { Injectable } from '@angular/core';
import { CustomFormGroup } from './../models/form/custom-form-group.model';
import { ErrorModel } from './../models/form/error.model';
import { QuestionService } from './question.service';
import { HttpService } from './http.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable, catchError, map, of, shareReplay, switchMap, throwError } from 'rxjs';
import { QuestionBase } from '../models/questions/question-base.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

@Injectable()
export class ValidationService {
	private readonly defaultErrorType: string = "internal-api";
	private allErrors: BehaviorSubject<ErrorModel[]> = new BehaviorSubject(new Array<ErrorModel>());

	private isValid = false;
	public attemptedToSubmit = false;

	constructor(private http: HttpService,
		private questionService: QuestionService) { }


	public validateClient(formGroup: CustomFormGroup, questions: QuestionBase<any>[]): Observable<boolean> {
		let questionIsInvalidResults = [];
		
		for (const question of questions) {
			var questionControl = formGroup.controls[question.key];
			var questionControlValue = formGroup.value[question.key];
			if (questionControl) {
				//important to check for invalid here, for some reason valid doesn't work
				//when disabiling a hidden question that has validation
				if (question.type == "string")
				{
					formGroup.value[question.key] = questionControlValue?.toString();
				}
				else {
					questionIsInvalidResults.push(questionControl.invalid);
				}
				questionControl.markAsDirty();
			}
			
		}

		if (questionIsInvalidResults.includes(true)) {
			return of(false);
		}

		return of(true);
	}

	public validateQuote<T>(form: CustomFormGroup, url?: string): Observable<T> {
		let parsedForm = this.parseDatesBooleansAndBlankFieldsWithinForm(form.value);

		if (!url) {
			url = this.questionService.formPostBackUrl;
		}

		return this.http.post<T>(url, parsedForm)
		.pipe(
			map(response => {
				this.resetValidation();
				return response as T;
			}),
			catchError(response => {
				this.attemptedToSubmit = true;
				this.isValid = false;
				this.addQuoteErrors(response.error.modelState);
				return throwError(() => response.error.modelState);
			})
		);
	}


	public validateForm<T>(form: FormGroup, url: string, intendToSubmit: boolean): Observable<T> {
		let parsedForm = this.parseDatesBooleansAndBlankFieldsWithinForm(form.value);

		if (!url) {
			url = this.questionService.formPostBackUrl;
		}

		return this.http.post<T>(url, parsedForm).pipe(
			map(response => {
				this.resetValidation();
				this.isValid = true;
				return response as T;
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				if (!this.attemptedToSubmit) {
					this.attemptedToSubmit = intendToSubmit;
				}
				
				this.handleValidationResponseError(errorResponse);

				return throwError(() => errorResponse);
			})
		);
	}

	private handleValidationResponseError(errorResponse: HttpErrorResponse) {
		//TODO: check for other errors, 500 etc
		if (errorResponse.status === 400) {
			let formErrors = errorResponse.error.modelState;
			this.addQuoteErrors(formErrors);
		}

	}

	public validateApp<T>(form: CustomFormGroup, version: number): Observable<T> {
		let parsedForm = this.parseDatesBooleansAndBlankFieldsWithinForm(form.value);
		this.removeErrorsByErrorType(this.defaultErrorType);
		let url = `${this.questionService.formPostBackUrl}/${parsedForm['Id']}/ifa-step`;
		parsedForm.version = version;

		return this.http.put<T>(url, parsedForm)
		.pipe(
			map(response => {
				this.isValid = true;
				this.removeErrorsByErrorType(this.defaultErrorType);
				return response as T;
			}),
			catchError(response => {
				this.addAppErrors(response.error.modelState);
				
				return throwError(() => response.error.modelState);
			})
		);
	}

	public canWeSubmit() {
		this.attemptedToSubmit = true;
		let errors = this.allErrors.getValue();
		if (errors[0]) {
			this.scrollToFirstInvalid(errors[0]);
		}
		return this.isValid;
	}

	public getCurrentQuestionsErrors(currentQuestions: QuestionBase<any>[]): Observable<ErrorModel[]> {
		return this.getErrors().pipe(
			switchMap((errors: ErrorModel[]) => {
				let currentErrors: ErrorModel[] = [];
				for (const error of errors) {
					for (const question of currentQuestions) {
						if (question.key && (error.Name.toLowerCase() === question.key.toLowerCase() || error.Name.split('.')[0].toLowerCase() === question.key.toLowerCase())) {
							currentErrors.push(error);
						}
					}
				}
				return of(currentErrors);
			})
		)
	}

	public getQuestionErrors(questionKey: string): Observable<ErrorModel> {
		return this.getErrors().pipe(
			switchMap((errors: ErrorModel[]) => errors.filter(x => x.Name.toLowerCase() === questionKey?.toLowerCase()))
		)
	}

	public hasTriedToSubmit() {
		return this.attemptedToSubmit;
	}

	public getErrors() {
		return this.allErrors.asObservable().pipe(shareReplay(1));
	}

	public addError(key: string, errorMsg: string, type: string) {
		let errors = this.allErrors.getValue();
		let found = false;
		errors.forEach(error => {
			if (error.Name === key && error.Error === errorMsg) {
				found = true;
			}
		});

		let error = new ErrorModel();
		error.Name = key;
		error.Error = errorMsg;
		error.Type = type;
		if (!found) {
			errors.push(error);
			this.allErrors.next(errors);
		}
	}

	public addSubmitError(modelState: any) {
		for (let key in modelState) {
			if (key === 'invalidForm') {
				let element = document.getElementById('invalidForm');
				element!.innerText = modelState[key][0];
			}
		}
	}

	public removeSubmitError() {
		let element = document.getElementById('invalidForm');
		element!.innerText = '';
	}

	public removeErrors(fieldName: string) {
		let errors = this.allErrors.getValue();
		for (let i = 0; i < errors.length; i++) {
			if (errors[i].Name === fieldName) {
				errors.splice(i, 1);
			}
		}
		this.allErrors.next(errors);
	}

	public removeErrorsByErrorType(errorType: string) {
		let errors = this.allErrors.getValue();
		for (let i = 0; i < errors.length; i++) {
			if (errors[i].Type === errorType) {
				errors.splice(i, 1);
			}
		}
		this.allErrors.next(errors);
	}

	private resetValidation() {
		this.removeErrorsByErrorType(this.defaultErrorType);
		this.isValid = false;
		this.attemptedToSubmit = false;
	}

	private clearErrors() {
		this.allErrors.next(new Array<ErrorModel>());
	}

	// For some reason the modelState returned by
	// the quote and app process is formatted differently
	// not entirely sure why, but would be nice if they were the same
	private addAppErrors(modelState: any) {
		this.removeErrorsByErrorType(this.defaultErrorType);
		let errors = this.CopyExistingCustomErrors();

		for (let key in modelState) {
			if (key !== '$id' && modelState.hasOwnProperty(key)) {
				let obj = modelState[key];
				let validationError = new ErrorModel();
				if (obj.errors) {
					obj.errors.forEach((err: any) => {
						validationError.Type = this.defaultErrorType;
						validationError.Name = key;
						validationError.Error = err.errorMessage;
						errors.push(validationError);
					});
				}
			}
		}
		this.allErrors.next(errors);
	}

	private addQuoteErrors(modelState: any) {
		this.removeErrorsByErrorType(this.defaultErrorType);
		let errors = this.CopyExistingCustomErrors();
		for (let key in modelState) {
			if (key !== '$id' && modelState.hasOwnProperty(key)) {
				let obj = modelState[key];
				let validationError = new ErrorModel();
				if (obj.length !== 0) {
					for (let i = 0; i < obj.length; i++) {
						var keyName = key;
						validationError.Type = this.defaultErrorType;
						if (key.startsWith(this.questionService.modelBindingName)) {
							keyName = key.substring(key.indexOf('.') + 1);
						}
						validationError.Name = keyName;

						if (obj[i].indexOf('line') > 0 && obj[i].indexOf('position') > 0) {
							validationError.Error = keyName + ' is required.';
						}
						else {
							validationError.Error = obj[i];
						}
						errors.push(validationError);
					}
				}
			}
		}
		if (errors[0] && !this.questionService.continuousValidation) {
			this.scrollToFirstInvalid(errors[0]);
		}
		this.allErrors.next(errors);
	}

	//Create a new ErrorModel[] and copy all existing errors that contain key word "custom"
	private CopyExistingCustomErrors(): Array<ErrorModel> {
		let errors = new Array<ErrorModel>();
		let customErrorsToCopy = this.allErrors.getValue().filter(error => error.Type.indexOf("custom") !== -1);
		customErrorsToCopy.forEach(error => {
			errors.push(error);
		});

		return errors;
	}

	public parseDatesBooleansAndBlankFieldsWithinForm(formValue: any) {
		// Loop round all properties in body
		for (let key in formValue) {
			var formKeyValue = formValue[key];
			if (formValue.hasOwnProperty(key)) {
				// Try and parse each element as a date
				let date = moment(formValue[key], 'DD/MM/YYYY', true);
				// If its a valid date and matches the expected length of a date
				if (date.isValid()) {
					// Change the format of the date
					formValue[key] = date.format('YYYY-MM-DD');
				}

				if (Boolean(formValue[key]))
				{
					if ( formKeyValue == "true" || formKeyValue == "false") {formValue[key] = formKeyValue === "true"};
				}
				
				if (formKeyValue == "")
				{
					 formValue[key] = null;
				}
			}
		}
		// Return new body with formatted values
		return formValue;
	}

	private scrollToFirstInvalid(error: ErrorModel) {
		if (error.Name !== undefined) {
			let nameParts = error.Name.split('.');
			let name = error.Name;
			if (nameParts.length > 1) {
				name = nameParts[0];
			}
			name = name[0].toUpperCase() + name.substr(1, name.length);
			let element = document.getElementById('form-' + name);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				window.scrollBy(0, -50);
			} else {
				let element = document.getElementById('invalidHiddenControls');
				element!.innerText = `${name} is invalid but currently hidden - If you would like some help, please call 0800 526 249.`;
				element!.scrollIntoView();
			}
		}
	}
}