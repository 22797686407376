<div class="informationSection NW20-informationSection">
  <p class="title form__subheading">
    {{ question.label }}
  </p>
  <p
    *ngIf="question.value"
    class="content form__intro"
    [ngClass]="question.classes"
  >
    {{ question.value }}
  </p>

  <ng-container *ngIf="multitext">
    <app-dynamic-multi-text [content]="multitext"></app-dynamic-multi-text>
  </ng-container>
</div>
