<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
<section *ngIf="loginDisplay; else signIn">
  <div class="dc-dashboard">
    <div class="mb-3">
      <header class="dc-dashboard__header">
        <h1>Submitted claims</h1>
  <button class="btn primary no-border" [routerLink]="['/form/death/claim']" *ngIf="loginDisplay">
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <path
        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
          </svg>
          <span>New claim</span>
        </button>
      </header>



      <div *ngIf="(deathClaims$ | async) as deathClaims; else noClaims">
        <div *ngFor="let deathClaim of deathClaims">
              <app-death-claim-overview [deathClaim]="deathClaim"></app-death-claim-overview>
          </div>
          <div *ngIf="deathClaims.length > 0; else noClaims">
          </div>
        </div>
    </div>
  </div>
</section>


<ng-template #signIn>
  <div class="info-message">
    <div class="info-message__wrapper">
      <h1 class="info-message__title">Bereavement Claim</h1>
      <div class="sf-card info-message__welcome-message">
        <p>
          We understand that dealing with the death of a loved one is never
          easy, so it's our aim to make the claims process as simple as
          possible. Below, you can log in to manage an existing claim or
          register to make a new claim.
        </p>
        <p>
          If you are having trouble logging in or registering, please contact us
          on 0800 526 249 or
          <a href="mailto:members@shepherdsfriendly.co.uk"
            >members@shepherdsfriendly.co.uk</a
          >
          and we will be more than happy to help.
        </p>
      </div>

      <div class="info-message__row">
        <div class="sf-card info-message__item">
          <h2>Bereavement Claim Log-in</h2>
          <p>
            If you have already registered to make a claim, please log in to
            view the details.
          </p>

          <button
            class="btn primary info-message__button info-message__button--login"
            (click)="login()"
          >
            <span>Log-in</span>
          </button>
        </div>

        <div class="sf-card info-message__item">
          <h2>Register</h2>
          <p>
            To make a new claim, you will need to register for an account with
            us. If you have already registered, please log in to view your
            claim.
          </p>
          <!-- TODO: rauter link -->
          <button
            class="btn secondary info-message__button"
            (click)="register()"
          >
            <span>Register</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noClaims>
  <div class="sf-card claims mt-4">
    <p>You currently have no claims.</p>
  </div>
</ng-template>
