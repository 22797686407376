import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDynamicFormfield } from '../../models/dynamic-formfield.interface';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionBase, QuestionDependency } from '../../models/questions/question-base.model';
import { QuestionTextbox } from '../../models/questions/question-textbox.model';
import { DependenciesService } from '../../services/dependencies.service';
import { BehaviorSubject, Observable, Subscription, map, switchMap } from 'rxjs';
import { Dependency } from '../../models/dependency.model';

@Component({
	selector: 'app-dynamic-form-question',
	templateUrl: 'dynamic-form-question.component.html',
	styleUrls: ['dynamic-form-question.component.css']
})
export class DynamicFormQuestionComponent implements OnInit, IDynamicFormfield {
	@Input() question: QuestionBase<any>;
	@Input() form: CustomFormGroup;
	@Input() errors: string[];
	@Output() eventEmitter = new EventEmitter();

	showLabel: boolean;
	hideQuestion$: Observable<boolean>;
	trackedDependencies$: BehaviorSubject<Dependency[]>;

	private subscription: Subscription;

	constructor(private dependenciesService: DependenciesService) { }

	ngOnInit() {
		this.initaliseQuestionDependencyTracking();

		// Start broadcasting this questions value changes
		this.subscription = this.form.controls[this.question.key]?.valueChanges
		.subscribe(value => {
			this.dependenciesService.broadcast(this, value);
		});

		this.showLabel = (this.question as QuestionTextbox).type !== 'hidden'
			&& this.question.controlType !== 'header'
			&& this.question.controlType !== 'address'
			&& this.question.controlType !== 'plan-length'
			&& this.question.controlType !== 'information';
	}

	initaliseQuestionDependencyTracking(): void {
		
		// initalise the current values of dependencies we need to track, if we have any
		let trackedDependencies = this.question.dependencies?.flatMap(dependencies => {
			// initalising by adding the initial form values to each dependency
			return dependencies.group.map(keyValue => {
				let formValue = this.form.controls[keyValue.key].value;
				return new Dependency(keyValue.key, formValue)
			});
		});

		// if we have any dependencies start monitoring any value changes
		if (trackedDependencies) {
			this.trackedDependencies$ = new BehaviorSubject<Dependency[]>(trackedDependencies);

			this.hideQuestion$ = this.dependenciesService.receive().pipe(
				switchMap((dependency: Dependency) => 
					this.updateQuestionDependencyCache(dependency)
				),
				map((dependenciesCurrentValues: Dependency[]) => {
					let shouldHide = this.shouldHideQuestion(this.question.dependencies, dependenciesCurrentValues);
					
					if (this.question.dependencies) {
						this.handleQuestionControlStatus(shouldHide, this.question.key);
					}

					return shouldHide;
				})
			);
		}
	}

	// if the dependency matches one being tracked on this question, then update the value
	updateQuestionDependencyCache(dependency: Dependency): Observable<Dependency[]> {
		return this.trackedDependencies$.pipe(
			map(currentDependecies => {
				let dependencyChanged = currentDependecies.find(x => x.key === dependency.key);
				if (dependencyChanged) {
					//toString() dependency for checkboxes, which are boolean values
					dependencyChanged.value = dependency.value?.toString();
				}

				return currentDependecies;
			})
		);
	}

	shouldHideQuestion(questionDependencies: QuestionDependency[] | undefined, currentDependeciesValues: Dependency[]): boolean {
		let shouldHide = true;

		var RepeaterItem = (questionDependencies?.find(x => x.type == "repeater-item"));

		if (RepeaterItem)
		{
			if(this.dependenciesService.handleRepeater(RepeaterItem,currentDependeciesValues))
			{
				return true;
			}
		}

		// This handles AND dependencies within a single dependency group. Where there are
		// are several dependency groups they will be processed sequentially until a match is
		// found or there are no matching dependency groups. 
		// This gives some level of OR capability increasing the flexibility of the dependency config.
		questionDependencies?.some(questionDependencyGroup => {
			shouldHide = this.dependenciesService.handle(questionDependencyGroup, currentDependeciesValues);
			return shouldHide;
		});

		return shouldHide;
	}

	// enable/disable questions for handling validation on hidden fields
	handleQuestionControlStatus(shouldHide: boolean, questionKey: string): void {
		let control = this.form.controls[questionKey];

		if (!control)
			return;

		shouldHide ? 
			control.disable({ onlySelf: true, emitEvent: false }) :
			control.enable({ onlySelf: true, emitEvent: false })

		control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}