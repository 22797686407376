import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

declare var window: any;

@Injectable()

export class GaTrackingService {

	constructor(
		private title: Title) { }

	sendPage(stepNumber: Number, path: String) {

		switch (path) {
			case '/form/bonds/application':
				this.title.setTitle('5 Year Fixed Rate Bond Application - Shepherds Friendly');
				break;

			case '/form/claims/income-protection-claim':
				this.title.setTitle('Shepherds Friendly Society | Income Protection Claim Form');
				break;

			default:
				break;
		}

		window.dataLayer.push({'event': 'pageview', 'page':  path + '/step-' + stepNumber});
	}
}
