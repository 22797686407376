import { Component, Input, OnInit } from '@angular/core';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { DocumentModel } from '../../models/form/document.model';
import { QuestionDocument } from '../../models/questions/question-document.model';

@Component({
  selector: 'app-dynamic-document',
  templateUrl: './dynamic-document.component.html',
  styleUrls: ['./dynamic-document.component.css']
})
export class DynamicDocumentComponent implements OnInit {
  @Input() question: QuestionDocument;
  @Input() form: CustomFormGroup;

  document: DocumentModel | null;

  ngOnInit() {
    //value at this point is a json string, would be nice if QuestionBase would already handle this.
    this.document =  this.tryParseValue();
  }

  tryParseValue(): DocumentModel | null {
    try {
      return this.question.value ? 
        JSON.parse(this.question.value.toString()) as DocumentModel :
        null;

    } catch (error) {
      return null;
    }
  }
}
