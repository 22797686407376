import { Component, Input } from '@angular/core';
import { IDynamicFormfield } from '../../models/dynamic-formfield.interface';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionCheckbox } from '../../models/questions/question-checkbox.model';

@Component({
	selector: 'app-dynamic-checkbox',
	templateUrl: 'dynamic-checkbox.component.html',
	styleUrls: ['dynamic-checkbox.component.css']
})
export class DynamicCheckboxComponent implements IDynamicFormfield {
	@Input() question: QuestionCheckbox;
	@Input() form: CustomFormGroup;
}
