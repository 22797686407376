import { Component, OnInit, Input } from '@angular/core';
import { IDynamicFormfield } from '../../models/dynamic-formfield.interface';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionRadio } from '../../models/questions/question-radio.model';

@Component({
	selector: 'app-dynamic-radio',
	templateUrl: 'dynamic-radio.component.html',
	styleUrls: ['dynamic-radio.component.css']
})
export class DynamicRadioComponent implements OnInit, IDynamicFormfield {
	@Input() question: QuestionRadio;
	@Input() form: CustomFormGroup;
	preValue: string;
	selectedValue: string;

	ngOnInit() {
		// Try and set preValue to existing value in form (in case we're amending)
		// If not, use the value provided in the question
	
		if (this.form.controls[this.question.key].value !== null && this.form.controls[this.question.key].value !== '') {
			this.preValue = this.form.controls[this.question.key].value.toString();
		} else if (this.question.value) {
			this.preValue = this.question.value.toString();
		}
	}

	ngAfterViewInit() {
		//Assign any pre values
		if (this.preValue) {
			this.form.controls[this.question.key].patchValue(this.preValue);
		}
	}

	updateValue(value: string) {
		this.selectedValue = value;
		this.form.controls[this.question.key].setValue(value);
		this.form.controls[this.question.key].markAsDirty();
		this.form.controls[this.question.key].markAsTouched();
	}

	selected(value: string) {
		return this.selectedValue === value;
	}
}
