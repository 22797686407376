export class AddressModel {
	public line1: string;
	public line2: string;
	public city: string;
	public county: string;
	public postcode: string;

	constructor(
		line1 = '',
		line2 = '',
		city = '',
		county = '',
		postcode = '') {

		this.line1 = line1;
		this.line2 = line2;
		this.city = city;
		this.county = county;
		this.postcode = postcode;
	}
}
