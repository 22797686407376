import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { IDynamicFormfield } from '../../models/dynamic-formfield.interface';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionBase } from '../../models/questions/question-base.model';

@Component({
	selector: 'app-dynamic-table',
	templateUrl: 'dynamic-table.component.html',
	styleUrls: ['dynamic-table.component.css']
})
export class DynamicTableComponent implements AfterViewInit, IDynamicFormfield {
	@Input() question: QuestionBase<any>;
	@Input() form: CustomFormGroup;

	ngAfterViewInit(): void {
		this.question.options?.forEach(option => {
			if (option.value.includes('colspan')) {
				let element = document.getElementById('column_' + option.key);
				let colspanKeyValue = option.value.split(':');
				element?.setAttribute(colspanKeyValue[0], colspanKeyValue[1]);
			}
		})		
	}
}


