import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as moment from 'moment';

export class CustomFormValidators
{
  static futureDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {  
      if (!this.isValidDate(control.value))
        return null;
      
      if (!this.isFutureDate(control.value)) {
        return {futureDate: {value: control.value}};
        
      }

      return null;
    };
  }

  static notFutureDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.isValidDate(control.value))
        return null;

      if (this.isFutureDate(control.value)) {
        return {notFutureDate: {value: control.value}};
        
      }

      return null;
    };
  }  

  static validDate() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.isValidDate(control.value)) {
        return {validDate: {value: control.value}};
      }
      return null;
    };
  }

  static validSurvivingMember(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let survivingMembers = control.value?.filter((x: any) => x?.isChecked)
      if(survivingMembers == null || survivingMembers.length == 0)
      {
        return null;
      }
      if (!this.isValidSurvivingMember(survivingMembers)) {
        return {validSurvivingMember: {value: control.value}};
      }
      return null;
    };
  }  

  private static isValidSurvivingMember(value : {keyvalue: {key: string, value: string}, isChecked: boolean}[]) : boolean
  {
    if (value?.find(x => x?.keyvalue.key === "No") && value.length > 1) {
      return false;
    }
    return true;
  }

  private static isValidDate(value : any) : boolean
  {
    let date = moment(value, 'DD/MM/YYYY', true);
    // If its a valid date and matches the expected length of a date
    if (!date.isValid()) {
      return false;
    }
    return true;
  }

  private static isFutureDate(value : any) : boolean
  {
    let date = moment(value, 'DD/MM/YYYY', true);
    var todayDate = moment();
    
    if (todayDate.isAfter(date)) {
      return false;
    }
    else
    {
    return true;
    }
  }
}

