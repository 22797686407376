import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export const environment = {
    production: true,
    apiUrl: 'https://sf1-uat-core-apim-2ncz.azure-api.net',
    formPath: '/death/claim',
    path: '/death'
};



 
export const b2cPolicies = {
     names: {
         signUpSignIn: "B2C_1_SignUp_SignIn",
         editProfile: "B2C_1_SignUp_SignIn"
     },
     authorities: {
         signUpSignIn: {
             authority: "https://sfdevB2C.b2clogin.com/sfdevB2C.onmicrosoft.com/B2C_1A_SignUp_SignIn",
         },
         editProfile: {
             authority: "https://sfdevB2C.b2clogin.com/sfdevB2C.onmicrosoft.com/B2C_1A_SignUp_SignIn"
         }
     },
     authorityDomain: "sfdevB2C.b2clogin.com"
 };
 
 
export const msalConfig: Configuration = {
     auth: {
         clientId: '730c4d7b-8091-47df-9611-d6b8bae212d2',
         authority: b2cPolicies.authorities.signUpSignIn.authority,
         knownAuthorities: [b2cPolicies.authorityDomain],
         redirectUri: '/', 
     },
     cache: {
         cacheLocation: BrowserCacheLocation.LocalStorage,
         storeAuthStateInCookie: isIE, 
     },
     system: {
         loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                console.log(message);
             },
             logLevel: LogLevel.Verbose,
             piiLoggingEnabled: false
         }
     }
 }

export const protectedResources = {
  ApimApi:{
    endpoint:`${environment.apiUrl}/*`,
    scopes:[msalConfig.auth.clientId]
  }
}