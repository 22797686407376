import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDynamicFormfield } from '../../models/dynamic-formfield.interface';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionButton } from '../../models/questions/question-button.model';

@Component({
	selector: 'app-dynamic-button',
	templateUrl: './dynamic-button.component.html',
	styleUrls: ['./dynamic-button.component.css']
})
export class DynamicButtonComponent implements OnInit, IDynamicFormfield {
	@Input() question: QuestionButton;
	@Input() form: CustomFormGroup;
	@Output() eventEmitter = new EventEmitter<string>();

	ngOnInit() {
		// Get quote and confirm applications are always the last components to be loaded
		// So we want to call home and tell DynamicFormComponent that we've finished loading
		if (this.question.action === 'getQuote' || this.question.action === 'confirmApplication') {
			this.eventEmitter.emit('loaded');
		}
	}
}
