import { Inject, Injectable } from '@angular/core';

import { HttpService } from './http.service';

import { AppConstantsService } from './app-constants.service';

import { FormModel, StepModel } from './../models/form/form.model';
import { QuestionBase } from './../models/questions/question-base.model';
import { QuestionTextbox } from './../models/questions/question-textbox.model';
import { QuestionDropdown } from './../models/questions/question-dropdown.model';
import { QuestionRadio } from './../models/questions/question-radio.model';
// import { QuestionIsaTransferRadio } from '../models/questions/question-isa-transfer-radio.model';
import { QuestionCheckbox } from './../models/questions/question-checkbox.model';
import { QuestionAddress } from './../models/questions/question-address.model';
// import { QuestionBmi } from './../models/questions/question-bmi.model';
import { QuestionButton } from './../models/questions/question-button.model';
// import { QuestionTeleInterview } from './../models/questions/question-tele-interview.model';
// import { QuestionOccupation } from './../models/questions/question-occupation.model';
import { QuestionDirectDebit } from './../models/questions/question-directdebit.model';
// import { QuestionExtraMedicalInfo } from './../models/questions/question-extra-medical-info.model';
// import { QuestionCtfDob } from './../models/questions/question-ctf-dob.model';
import { QuestionText } from './../models/questions/question-text.model';
import { QuestionHeader } from './../models/questions/question-header.model';
// import { QuestionSportsAndPastimes } from './../models/questions/question-sports-and-pastimes.model';
// import { QuestionPlanLength } from './../models/questions/question-plan-length.model';
import { QuestionInformation } from './../models/questions/question-information.model';
// import { QuestionClaimsSummary } from './../models/questions/question-claims-summary.model';
// import { QuestionCountryTax } from './../models/questions/question-country-tax.model';
// import { TableModel } from './../models/form/table.model';
import { LinkModel } from './../models/form/link.model';
import { Observable, map } from 'rxjs';
import { QuestionRepeater } from '../models/questions/question-repeater.model';
import { AppConfig } from '../../app-settings/config/app-config';

@Injectable()
export class QuestionService {

  submissionEndpoint: string;
  validationEndpoint: string;
  resumeEndpoint: string;
  nextUrl: string;
  formVersion: number;
  modelBindingName: string;
  continuousValidation: boolean;

  private _formName: string = "";
  get formName(): string {
    return this._formName;
  }
  set formName(name: string) {
    this._formName = name;
  }

  private _formTitle: string = "";
  get formTitle(): string {
    return this._formTitle;
  }
  set formTitle(title: string) {
    this._formTitle = title;
  }

  private _steps: StepModel[] = [];
  get steps(): StepModel[] {
    return this._steps;
  }

  set steps(steps: StepModel[]) {
    this._steps = steps;
  }

  private _formPostBackUrl: string = '';
  get formPostBackUrl(): string {
    return this._formPostBackUrl;
  }
  set formPostBackUrl(type: string) {
    this._formPostBackUrl = type;
  }

  private _formType: string;
  public get formType(): string {
    return this._formType;
  }
  public set formType(v: string) {
    this._formType = v;
  }

  private _questions: QuestionBase<any>[];
  public get questions(): QuestionBase<any>[] {
    return this._questions;
  }
  public set questions(v: QuestionBase<any>[]) {
    this._questions = v;
  }

  constructor(
    @Inject(AppConfig) private config: AppConfig,
    private httpClient: HttpService
  ) {}

  initialize(formName: string, formType: string, id: string): Observable<QuestionBase<any>[]> {

    let formPath =`/Api/Forms/${formName}/Start?formType=${formType}&id=${id}`;

    if (this.config.formPath) {
      let formId = id ? `/${id}`: '';
      formPath = `${this.config.formPath}${formId}/form`
    }

    return this.httpClient.get<FormModel>(`${this.config.api}${formPath}`)
		.pipe(
      map((form) => {
        let dynamicForm = form;
        this.formName = dynamicForm.name;
        this.formTitle = dynamicForm.title;
        this.formPostBackUrl = dynamicForm.postBackUrl;
        this.formType = dynamicForm.formType;
        this.steps = dynamicForm.steps;
        this.questions = this.generateTypes(dynamicForm.fields);

        this.submissionEndpoint = form.submissionEndpoint;
        this.validationEndpoint = form.validationEndpoint;
        this.nextUrl = form.nextUrl;
        this.formVersion = form.formVersion;
        this.modelBindingName = form.modelBindingName;
        this.continuousValidation = form.continuousValidation;

        return this.questions;
      })
    );
  }

  private generateTypes(questions: QuestionBase<any>[]): QuestionBase<any>[] {
    for (let i = 0; i < questions.length; i++) {
      questions[i] = this.getType(questions[i]);

      // generate types for any children
      let questionChildren = questions[i].children;
      if (questionChildren && questionChildren.length > 0) {
        this.generateTypes(questionChildren);
      }

      //generate types for any items in the table
      let tableRows = questions[i].table?.tRows;
      if (tableRows) {
        for (let index = 0; index < tableRows.length; index++) {
          const tableRow = tableRows[index];
          this.generateTypes(tableRow.columns);
        }
      }
    }
    return questions;
  }

  getType(question: any) {

    switch (question.controlType) {
      case 'text':
        return new QuestionText(question);
      case 'checkbox':
        return new QuestionCheckbox(question);
      // case 'bmi':
      // 	return new QuestionBmi(question);
      // case 'teleinterview':
      // 	return new QuestionTeleInterview(question);
      case 'directdebit':
        return new QuestionDirectDebit(question);
      case 'textbox':
        return new QuestionTextbox(question);
      case 'link':
        return new LinkModel(question);
      case 'button':
        return new QuestionButton(question);
      case 'address':
        return new QuestionAddress(question);
      // case 'occupation':
      // 	return new QuestionOccupation(question);
      // case 'table':
      // 	return new QuestionTable(question);
      case 'radio':
        return new QuestionRadio(question);
      // case 'isa-transfer-radio':
      // 	return new QuestionIsaTransferRadio(question);
      // case 'sportsandpastimes':
      // 	return new QuestionSportsAndPastimes(question);
      case 'dropdown':
        return new QuestionDropdown(question);
      // case 'extra-medical-info':
      // 	return new QuestionExtraMedicalInfo(question);
      // case 'ctf-dob':
      // 	return new QuestionCtfDob(question);
      case 'header':
        return new QuestionHeader(question);
      // case 'plan-length':
      // 	return new QuestionPlanLength(question);
      case 'summary':
      case 'declaration':
      case 'table':
      case 'group':
        return new QuestionBase(question);
      // case 'claims-summary':
      // return new QuestionClaimsSummary(question);
      case 'information':
        return new QuestionInformation(question);

      case 'repeater':
        return new QuestionRepeater(question);

      case null:
        return question;


      // case 'document-upload':
      // 	return new QuestionDocumentUpload(question);
      // case 'country-tax':
      // 	return new QuestionCountryTax(question);
      default:
        console.error(`Unknown control type "${question.controlType}" for control "${question.key}"`);
        throw new Error(`Unknown control type "${question.controlType}" for control "${question.key}"`);
    }
  }
}
