import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { EMPTY, Observable, catchError, map, of, shareReplay, throwError } from 'rxjs';
import { Document } from '../models/document.model';
import { DeathClaim } from '../models/death-claim.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class DeathClaimsService {
  // TODO: update to use app config
  api: string = environment.apiUrl+environment.path;

  constructor(private http: HttpService) {}

  // private testClaims: DeathClaim[] = test.getDeathClaims();

  getClaims(): Observable<DeathClaim[]> {
      return this.http.get<DeathClaim[]>(`${this.api}/claims`).pipe(
        shareReplay(1),
        catchError(e => 
          this.handleError(e))
      );
  }


  getClaim(id: string) : Observable<DeathClaim | null> {

    // return of(this.testClaims.find(x => x.claimId === id) ?? null);

    return this.http.get<DeathClaim>(`${this.api}/claim/${id}`).pipe(
      catchError(e => 
        this.handleError(e))
    );
  }

  uploadDocument(id: string, doucmentId: string, file: File) : Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    return this.http.post(`${this.api}/claim/${id}/document/${doucmentId}`,formData).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(e => 
        this.handleError(e))
    );
  }

  getDocuments(id: string): Observable<Document[] | null> {
    return this.http.get<Document[]>(`${this.api}/claim/${id}/documents`).pipe(
      catchError(e => 
        this.handleError(e))
    );
  }

  getDocument(id: string, documentId: string) {
    return this.http.get<Document[]>(`${this.api}/claim/${id}/document/${documentId}`).pipe(
      catchError(e => 
        this.handleError(e))
    );
  }

  createClaim() {
    return this.http.get<string>(`${this.api}/claim/form`).pipe(
      catchError(e => 
        this.handleError(e))
    );
  }

  getSchema() {
    return this.http.get(`${this.api}/claim`).pipe(
      catchError(e => 
        this.handleError(e))
    );
  }

  handleError(error : any) {
    if (error.error != null && error.error.message != null)
    {
      return throwError(() => {
        return error.error.message;
      })
    } else {
      return throwError(() => {
        return "Error with service. Please try again"
      })
    }
  }
}
