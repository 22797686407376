import { Injectable } from '@angular/core';

declare var jQuery: any;

@Injectable()
export class ModalService {

	constructor() { }

	showModal() {
		if (this.isBadBrowserPresent() === -1) {
			jQuery('#loadingModal').modal('show');
		}

	}

	hideModal() {
		if (this.isBadBrowserPresent() === -1) {
			setTimeout(() => {
				jQuery('#loadingModal').modal('hide');
			}, 10);
		}
	}

	showCriticalErrorModal(error: string) {
		jQuery('#criticalErrorModal').modal('show');
		let element = document.getElementById('criticalErrorModalExceptionMessage');
		if (element) {
			element.innerText = error;
		}
	}

	showErrorModal(error: string) {
		jQuery('#errorModal').modal('show');
		let element = document.getElementById('errorModalExceptionMessage');
		if (element) {
			element.innerText = error;
		}
	}

	private isBadBrowserPresent() {
		let ua = window.navigator.userAgent;

		let msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		let trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			let rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		let edge = ua.indexOf('Edge/');
		if (edge > 0) {
			// Edge (IE 12+) => return version number
			return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
		}

		// other browser
		return -1;
	}

}
