import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  APP_DI_CONFIG,AppConfig } from './config/app-config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: AppConfig,
      useValue: APP_DI_CONFIG
    },
  ]
})
export class AppConfigModule { }
