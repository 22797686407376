import {
  Component,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { IDynamicFormfield } from "../../models/dynamic-formfield.interface";
import { CustomFormGroup } from "../../models/form/custom-form-group.model";
import { QuestionBase } from "../../models/questions/question-base.model";


@Component({
  selector: "app-dynamic-form-question-group",
  templateUrl: "dynamic-form-question-group.component.html",
  styleUrls: ["dynamic-form-question-group.component.css"],
})
export class DynamicFormQuestionGroupComponent implements IDynamicFormfield
{
  @Input() question: QuestionBase<any>;
  @Input() form: CustomFormGroup;
  @Input() errors: string[];
  @Output() eventEmitter = new EventEmitter();
}
