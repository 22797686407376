import { Component, Input } from '@angular/core';
import { IDynamicFormfield } from '../../models/dynamic-formfield.interface';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionHeader } from '../../models/questions/question-header.model';

@Component({
	selector: 'app-dynamic-header',
	templateUrl: './dynamic-header.component.html',
	styleUrls: ['./dynamic-header.component.css']
})
export class DynamicHeaderComponent implements IDynamicFormfield {
	@Input() question: QuestionHeader;
	@Input() form: CustomFormGroup;
}
