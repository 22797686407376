
import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, Subject, map, share, switchMap } from 'rxjs';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionBase } from '../../models/questions/question-base.model';
import { FormService } from '../../services/form.service';
import { QuestionControlService } from '../../services/question-control.service';
import { QuestionService } from '../../services/question.service';
import { ValidationService } from '../../services/validation.service';
import { StepModel } from '../../models/form/form.model';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: 'dynamic-form.component.html',
  styleUrls: ['dynamic-form.component.css'],
  providers: [QuestionControlService]
})
export class DynamicFormComponent implements OnDestroy {
  form: CustomFormGroup;
  quoteErrors: { key: string, error: string }[];
  loaded: Subject<boolean> = new Subject<boolean>();
  pageId: string;

  constructor(public questionControlService: QuestionControlService,
    public qs: QuestionService,
    public router: Router,
    public route: ActivatedRoute,
    public validationService: ValidationService,
    public formService: FormService,
    public renderer: Renderer2) { }


  formParams$ = this.route.params.pipe(
    map((params: Params) => {
      return {name: params['formName'], type: params['formType'], id: params['id']}
    })
  );

  questions$: Observable<QuestionBase<any>[]> = this.formParams$.pipe(
    switchMap((formParams) => {
      window.scrollTo(0, 0);
      return this.getQuestions(formParams.name, formParams.type, formParams.id);
    }),
    share()
  );

  headerDisabled$: Observable<boolean> = this.questions$.pipe(
    map(questions => questions.find(x => x.key === "DisableHeader") ? true : false)
    )

  get steps(): StepModel[] {
    return this.qs.steps;
  }

  getQuestions(formName: string, formType: string, id: string) {
    return this.qs.initialize(formName, formType, id).pipe(
      map((questions: QuestionBase<any>[]) => {
        this.pageId = `page-id-${this.qs.formName}`;
        this.renderer.addClass(document.body, this.pageId);
        // Generate form based off quotes
        this.form = this.questionControlService.formGroup(questions);
        this.form.formType = this.qs.formType;
        this.formService.registerFormGroup(this.form, questions);

        if (this.qs.formVersion === 2) {
          this.formService.enableServerValidationPerChange();
        }

        return questions;
      })
    )
  }

  // Called when event is emitted from question
  // Allows question components to call form component methods
  eventHandler(event: string) {
    switch (event) {
      case 'submit':
        this.formService.submitForm();
        break;
      case 'nextStep':
      case 'step':
        this.formService.nextStep(this.form);
        break;
      case "loaded":
        this.loaded.next(true);
        break;
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, this.pageId)
  }
}
