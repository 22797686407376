<div
  [formGroup]="form"
  [attr.id]="'form-' + question.key"
  (clickOutside)="showDropDown = false"
>
  <button
    class="form__input form__input--dropdown form__input--button"
    (click)="showDropDown = !showDropDown"
  >
    <div>{{ checkedDisplayList$ | async }}</div>
  </button>
  <div class="form__input" *ngIf="showDropDown">
    <ul class="multiselect-list">
      <li *ngFor="let option of checkboxOptions">
        <label>
          <input
            type="checkbox"
            [checked]="option.isChecked"
            (change)="toggleOption(option)"
          />
          <span class="multiselect-label">{{ option.keyvalue.key }}</span>
        </label>
      </li>
    </ul>
  </div>
</div>
