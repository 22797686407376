<ng-container *ngIf="childQuestions">
    <ng-container *ngIf="(showPerValueDependency$ | async) as showPerValue"> 
        <ng-container *ngFor="let childQuestion of childQuestions">
            <div class="repeater-container">
                <div [ngClass]="childQuestion.classes">
                    <app-dynamic-form-question [form]="form" [question]="childQuestion"></app-dynamic-form-question>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

