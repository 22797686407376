<!-- <div class="card">
    <div class="card-header">
        Death Claim
    </div>

    <section class="card-body">
        <h5 class="card-title">{{deathClaim.memberForenames}}</h5>
        <h6 class="card-subtitle mb-2 text-body-secondary">Status: pending documents</h6>
        <p class="card-text">you need to submit your doucments</p>
        <a [routerLink]="['form/death/claim', deathClaim.id]" routerLinkActive="router-link-active" class="btn btn-primary" style="margin-right: 10px;">View details</a>
        <a [routerLink]="['documents', deathClaim.id]" routerLinkActive="router-link-active" class="btn btn-primary">View documents</a>
    </section> -->
<div class="sf-card dc-overview">
    <div class="dc-overview__details">
        <p><strong>Name: </strong>{{deathClaim.memberForenames}} {{deathClaim.memberSurname}}</p>
        <p><strong>Status:</strong> {{deathClaim.latestStage}}</p>
    </div>

    <div class="dc-overview__controls">
        <button [routerLink]="['form/death/claim', deathClaim.id]" *ngIf="!HasBeenSubmitted()" routerLinkActive="router-link-active" class="btn secondary btn-small no-border">Details</button>
        <button [routerLink]="['documents', deathClaim.id]" routerLinkActive="router-link-active" class="btn tertiary btn-small no-border">Documents</button>
    </div>

</div>
<!-- </div> -->