import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'my-app';
  private readonly _destroying$ = new Subject<void>();

  constructor
  (
    private broadcastService: MsalBroadcastService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.authService.setLoginDisplay();
    })
    this.addClickListner();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  addClickListner(): void {
    const mainItems = document.querySelectorAll('.footer__nav-list-item');

    mainItems.forEach((item) => {
      const link = item.querySelector('a');
      const submenu = item.querySelector('.footer__nav-submenu');
      const toogleIcon = item.querySelector('.footer__nav-list-icon');

      if (link && submenu && toogleIcon) {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          this.toogleSubMenu(submenu, toogleIcon);
        });
      }
    });
  }

  toogleSubMenu(submenu: Element, toogleIcon: Element): void {
    if (submenu instanceof HTMLElement && toogleIcon instanceof HTMLElement) {
      submenu.classList.toggle('footer__nav-submenu--active');
      toogleIcon.classList.toggle('footer__nav-list-icon--active');
    }
  }
}
