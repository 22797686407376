<table class="deceased-plan-table">
    <thead>
        <tr>
            <th *ngFor="let header of question.table?.tHeaders">{{header}}</th>
        </tr>
    </thead>

    <tbody>
        <tr [id]="'row_' + question.key + i" *ngFor="let row of question.table?.tRows; let i = index">
            <td [id]="'column_' + column.key" *ngFor="let column of row.columns">
                <app-dynamic-form-question [form]="form" [question]="column"></app-dynamic-form-question>
            </td>
        </tr>
    </tbody>
</table>
