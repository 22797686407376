<ng-container *ngIf="hasContent">
  <ng-container *ngFor="let contentSection of content">
    <ng-container [ngSwitch]="contentSection.textType.value">
      <ng-container *ngSwitchCase="'sub_header'">
        <p
          *ngFor="let item of contentSection.values"
          class="form__subheading"
          [ngStyle]="additionalStyles"
          [ngClass]="item.classes"
        >
          {{ item.text }}
        </p>
      </ng-container>

      <ng-container *ngSwitchCase="'paragraph'">
        <p
          *ngFor="let item of contentSection.values"
          class="form__intro"
          [ngStyle]="additionalStyles"
          [ngClass]="item.classes"
        >
          {{ item.text }}
        </p>
      </ng-container>

      <ng-container *ngSwitchCase="'bullet_point'">
        <ul
          class="form__intro"
          [ngStyle]="additionalStyles"
          [ngClass]="contentSection.classes"
        >
          <li
            *ngFor="let item of contentSection.values"
            [ngClass]="item.classes"
          >
            {{ item.text }}
          </li>
        </ul>
      </ng-container>

      <ng-container *ngSwitchCase="'custom'">
        <div
          *ngFor="let item of contentSection.values"
          [ngClass]="contentSection.classes"
        >
          <div [innerHtml]="item.text"></div>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ul
          class="form__intro"
          [ngStyle]="additionalStyles"
          [ngClass]="contentSection.classes"
        >
          <li
            *ngFor="let item of contentSection.values"
            [ngClass]="item.classes"
          >
            {{ item.text }}
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
