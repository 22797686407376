import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReactiveFormsModule } from '@angular/forms';

/* Changes start here. */
// Import MSAL and MSAL browser libraries.
import {
  MsalGuard,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

// Import the Azure AD B2C configuration

// Import the Angular HTTP interceptor.
import { HttpClientModule } from '@angular/common/http';
import { ProfileComponent } from './profile/profile.component';
import { TestFormComponent } from './test-form/test-form.component';
import { NavigationComponent } from './components/general/navigation/navigation.component';
import { DocumentUploadComponent } from './components/documents/document-upload/document-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { HomeComponent } from './components/home/home.component';
import { DeathClaimOverviewComponent } from './components/claims/death-claim-overview/death-claim-overview.component';
import { DeathClaimDocumentsComponent } from './components/documents/death-claim-documents/death-claim-documents.component';
import { HttpService } from './services/http.service';
import { DeathClaimsService } from './services/death-claims.service';
import { LoadingSpinnerComponent } from './components/general/loading-spinner/loading-spinner.component';
import { httpInterceptorProviders } from './interceptors/http-interceptor-providers';
import { LoadingService } from './services/loading.service';
import { DynamicFormsModule } from './modules/dynamic-forms/dynamic-forms.module';
import { EchoComponent } from './echo/echo.component';
import { msalConfig, protectedResources } from 'src/environments/environment';

/* Changes end here. */

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    HomeComponent,
    TestFormComponent,
    NavigationComponent,
    DocumentUploadComponent,
    DeathClaimOverviewComponent,
    DeathClaimDocumentsComponent,
    LoadingSpinnerComponent,
    EchoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // Import the HTTP client.
    HttpClientModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    // shepherds modules
    DynamicFormsModule,

    // Initiate the MSAL library with the MSAL configuration object
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        // The routing guard configuration.
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: protectedResources.ApimApi.scopes,
        },
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [
            protectedResources.ApimApi.endpoint,
            protectedResources.ApimApi.scopes,
          ],
        ]),
      }
    ),
  ],
  providers: [
    httpInterceptorProviders,
    MsalGuard,
    HttpService,
    DeathClaimsService,
    LoadingService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
