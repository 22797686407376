import { Component, OnInit, Input } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, debounceTime,  switchMap, filter, startWith } from 'rxjs/operators';
import { IDynamicFormfield } from '../../models/dynamic-formfield.interface';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionDirectDebit } from '../../models/questions/question-directdebit.model';
import { LoqateBankDetailsService } from '../../services/loqate-bank-details.service';
import { ValidationService } from '../../services/validation.service';
import * as moment from 'moment';

@Component({
	selector: 'app-dynamic-direct-debit',
	templateUrl: './dynamic-direct-debit.component.html',
	styleUrls: ['./dynamic-direct-debit.component.css']
})
export class DynamicDirectDebitComponent implements OnInit, IDynamicFormfield {
	@Input() question: QuestionDirectDebit;
	@Input() form: CustomFormGroup;

	private readonly customLoqateBankErrorType = "custom-directdebit";
	sortMask = "00-00-00";
	accountNumberMask = "00000000";
	ddCollectionTooltip: string;


	constructor(private loqateBankDetailsService: LoqateBankDetailsService, private validationService: ValidationService) { }

	ngOnInit() {
		//TODO move DD type short and default templates into seperate components
		if (this.question.type !== 'short') {
			this.calcNextDdCollectionDay();
		}

		this.watchDirectDebitValues().pipe(
			switchMap(([sortcode, accountNumber]) => 
				this.loqateBankDetailsService.bankVerification(sortcode, accountNumber)
			)
		).subscribe((response) => {
			if (!response) {
				return;
			} 

			if (response.successful === false) {
				this.validationService.addError(response.error.field, response.error.message, this.customLoqateBankErrorType);
			} else {
				this.validationService.removeErrorsByErrorType(this.customLoqateBankErrorType);
			}
		}, () => {});;
	}

	watchDirectDebitValues(): Observable<[string, string]> {
		let ddControls = this.form.controls[this.question.key] as CustomFormGroup;

		let sortcodeControl = ddControls.get('ddSortCode')!;
		let accountNumberControl = ddControls.get('ddAccountNumber')!;

		let obs$ = combineLatest(
			sortcodeControl.valueChanges.pipe(startWith("")),
			accountNumberControl.valueChanges.pipe(startWith(""))
		).pipe(
			debounceTime(1000),
			distinctUntilChanged(),
			filter(([sortcode, accountNumber]) => 
				this.unMask(sortcode).length === 6 &&
				this.unMask(accountNumber).length === 8
			)
		);

		return obs$;
	}

	unMask(val: string) {
		return val.replace(/\D+/g, '');
	}

	calcNextDdCollectionDay() {
		// Get todays date and the number of working days to add
		let date = moment();
		let workingDaysToAdd = 10;

		// Loop until we've got no more working days to add
		while (workingDaysToAdd != 0) {
			// Add one day to the current day
			date.add(1, 'days');
			// Get the day of the week (Monday = 1, Friday = 5 etc.)
			let weekday = date.isoWeekday();
			// If the day is a weekday, decrement workingDaysToAdd
			if (weekday !== 6 && weekday !== 7) {
				workingDaysToAdd--;
			}
		}

		// Loop until we find the next possible dd collection date
		while (true) {
			// Store the day of the month
			let day = date.date();
			// If it's not the 1st, 8th, 16th or 24th
			// Go to the next day and check again
			if (day !== 1 && day !== 8 && day !== 16 && day !== 24) {
				date.add(1, 'days');
			} else {
				break;
			}
		}

		// Add to tooltip property which is passed into dynamic-validation component 
		this.ddCollectionTooltip = `Due to setup time, the earliest possible direct debit collection date is ${date.format('Do MMMM YYYY')}`;
	}
}
