import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomFormGroup } from './../models/form/custom-form-group.model';
import { CustomFormControl } from './../models/form/custom-form-control.model';

import { HttpService } from './http.service';
import { QuestionService } from './question.service';
import { ValidationService } from './validation.service';
import { QuestionBase } from '../models/questions/question-base.model';
import { TrackingDetails } from '../models/form/tracking-details.model';
import {
  BehaviorSubject,
  Observable,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
} from 'rxjs';
import { ErrorModel } from '../models/form/error.model';

@Injectable()
export class FormService {
  private _currentStep = new BehaviorSubject<number>(1);
  private _currentQuestions = new BehaviorSubject<QuestionBase<any>[]>([]);
  currentStep$ = this._currentStep.asObservable();

  formGroup: CustomFormGroup;
  questions: QuestionBase<any>[];
  lastStep = 0;
  maxStepReached = 1;

  private _version = 0;

  constructor(
    private httpService: HttpService,
    private router: Router,
    public questionService: QuestionService,
    private validationService: ValidationService
  ) {}

  get version(): number {
    return this._version;
  }

  set version(version: number) {
    this._version = version;
  }

  get currentStep(): number {
    return this._currentStep.getValue();
  }
  set currentStep(val: number) {
    this._currentStep.next(val);
    this.maxStepReached = Math.max(this.currentStep, this.maxStepReached);
  }

  registerFormGroup(
    formGroup: CustomFormGroup,
    questions: QuestionBase<any>[]
  ) {
    this.formGroup = formGroup;
    this.questions = questions;

    // Find the last step
    Object.keys(this.formGroup.controls).forEach((key) => {
      const control = this.formGroup.get(key) as CustomFormControl;
      if (this.lastStep < control.step) {
        this.lastStep = control.step;
      }
    });

    this._currentStep
      .pipe(
        map((currentStep: number) => {
          let currentQuestions: QuestionBase<any>[] = [];

          getQuestionChildren(
            this.questions.filter((x) => x.step === currentStep)
          );

          function getQuestionChildren(questions: QuestionBase<any>[]) {
            questions.forEach((question) => {
              currentQuestions.push(question);
              if (question.children) {
                getQuestionChildren(question.children);
              }
            });
          }

          this._currentQuestions.next(currentQuestions);
        })
      )
      .subscribe();

    // setting initial step
    this._currentStep.next(1);
  }

  enableServerValidationPerChange() {
    this.formGroup.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((formValue) => {
        this.validationService
          .validateForm(
            this.formGroup,
            this.questionService.validationEndpoint,
            false
          )
          .subscribe();
      });
  }

  nextStep(formGroup: CustomFormGroup) {
    this.validateForm()
      .pipe(filter((valid) => valid))
      .subscribe(() => {
        this._currentStep.next(this.currentStep + 1);
        window.scrollTo(0, 0);
      });
  }

  //TODO: this needs refactoring
  handleServerValidation() {
    this.validationService
      .validateForm(
        this.formGroup,
        this.questionService.validationEndpoint,
        true
      )
      .subscribe({ next: () => {}, error: () => {} })
      .add(() => {
        this.validationService
          .getCurrentQuestionsErrors(this._currentQuestions.value)
          .subscribe((currentErrors: ErrorModel[]) => {
            if (!currentErrors || currentErrors.length === 0) {
              const formElement = document.getElementById('appTitle');
              formElement?.scrollIntoView();
              // Allow of initialise component test must be before currentStep increment
              this.currentStep++;
              this.validationService.attemptedToSubmit = false;
              // this.gaTrackingService.sendPage(this.formService.currentStep, this.router.url);
            } else {
              this.validationService.attemptedToSubmit = true;
            }
          })
          .unsubscribe();
      });
  }

  validateForm(): Observable<boolean> {
    return this.validationService
      .validateClient(this.formGroup, this._currentQuestions.value)
      .pipe(
        filter((clientValid) => clientValid),
        map(() => {
          if (this.questionService.validationEndpoint) {
            this.handleServerValidation();
            if (this.validationService.attemptedToSubmit) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
      );
  }

  submitForm() {
    this.validateForm()
      .pipe(filter((valid) => valid))
      .subscribe(() => {
        this.postForm();
      });
  }

  private postForm() {
    this.validationService.removeSubmitError();
    let parsedForm =
      this.validationService.parseDatesBooleansAndBlankFieldsWithinForm(
        this.formGroup.value
      );

    let trackingDetails = new TrackingDetails();

    trackingDetails.utmSource = localStorage.getItem('utm_source')!;
    trackingDetails.utmMedium = localStorage.getItem('utm_medium')!;
    trackingDetails.utmCampaign = localStorage.getItem('utm_campaign')!;
    trackingDetails.utmContent = localStorage.getItem('utm_content')!;
    trackingDetails.utmTerm = localStorage.getItem('utm_term')!;
    trackingDetails.promoCode = localStorage.getItem('promocode')!;

    parsedForm.TrackingDetails = trackingDetails;

    let submitEndpoint =
      this.questionService.submissionEndpoint ??
      this.questionService.formPostBackUrl;

    let nextUrl = this.questionService.nextUrl;

    this.httpService.post(submitEndpoint, parsedForm).subscribe({
      next: (submissionResponse: any) => {
        const routerInterpolation = new RegExp(/{([a-zA-Z]*)}/, 'g');
        const matches = this.questionService.nextUrl.match(routerInterpolation);
        let transactionId = '';

        if (matches) {
          for (let i = 0; i < matches.length; i++) {
            let property = matches[i].replace('{', '').replace('}', '');
            let propertyValue = submissionResponse[property];
            if (propertyValue) {
              nextUrl = nextUrl.replace(matches[i], propertyValue);
              if (property === 'policyNumber') {
                transactionId = propertyValue;
              }
            }
          }
        }

        //TODO: window.dataLayer was erroring, add this back in when needed
        if (nextUrl.includes('5-year-fixed-rate-bond')) {
          // window.dataLayer.push({
          // 	'event': 'gtm.saleComplete',
          // 	'tracking': {
          // 		'sale': {
          // 			'transactionId': transactionId
          // 		}
          // 	}
          // });
        }

        if (nextUrl.includes('5-year-fixed-rate-bond')) {
          // window.dataLayer.push({
          // 	'event': 'gtm.saleComplete',
          // 	'tracking': {
          // 		'sale': {
          // 			'transactionId': transactionId
          // 		}
          // 	}
          // });
        }
      }, // completeHandler
      error: (error) => {
        this.validationService.addSubmitError(error.json().modelState);
      },
      complete: () => {
        document.location.href = nextUrl;
      },
    });
  }
}
