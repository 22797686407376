import { environment } from "src/environments/environment";

export class AppConfig {
    api: string;
    formPath: string;
}
  
export const APP_DI_CONFIG: AppConfig = {
    api: environment.apiUrl,
    formPath: environment.formPath
};