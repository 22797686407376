import { StepModel } from './../../models/form/form.model';
import { Component, Input } from '@angular/core';
import { FormService } from '../../services/form.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css'],
})
export class StepsComponent<T> {
  @Input() steps: StepModel[];

  public currentStep$: Observable<number> = this.formService.currentStep$;

  constructor(private formService: FormService) {}

  public svgNumberPath: string[] = [
    'M11.472 16.734C11.472 16.874 11.584 17 11.738 17H12.942C13.082 17 13.194 16.874 13.194 16.734V7.746C13.194 7.606 13.082 7.48 12.942 7.48H11.962L9.94605 9.174C9.89005 9.23 9.86205 9.314 9.87605 9.426L9.96005 10.112C9.97405 10.238 10.114 10.322 10.24 10.294L11.472 10.028V16.734Z',
    'M9.47795 16.234C9.47795 16.374 9.58995 16.5 9.74395 16.5H15.2599C15.3999 16.5 15.5259 16.374 15.5259 16.234V15.268C15.5259 15.128 15.3999 15.002 15.2599 15.002H12.2359C12.9779 14.064 14.0419 12.748 14.6439 11.894C15.1899 11.11 15.5819 10.536 15.5819 9.626C15.5819 8.114 14.4619 6.84 12.4599 6.84C10.7939 6.84 9.70195 8.212 9.70195 8.212C9.60395 8.324 9.63195 8.492 9.71595 8.576L10.3879 9.248C10.4999 9.36 10.6539 9.36 10.7659 9.248C11.0879 8.898 11.6619 8.422 12.3339 8.422C13.3419 8.422 13.8179 9.066 13.8179 9.696C13.8179 10.172 13.5379 10.718 13.1039 11.25C12.1659 12.496 10.4019 14.792 9.47795 15.912V16.234Z',
    'M9.17978 15.394C9.48778 15.758 10.3418 16.64 12.0918 16.64C14.0238 16.64 15.3118 15.492 15.3118 13.91C15.3118 12.664 14.2758 11.908 13.6178 11.628V11.586C14.2478 11.292 15.0598 10.48 15.0598 9.402C15.0598 7.834 13.7018 6.84 12.0778 6.84C10.5098 6.84 9.44578 8.072 9.44578 8.072C9.34778 8.17 9.33378 8.338 9.44578 8.436L10.1318 9.08C10.2578 9.192 10.4118 9.178 10.5098 9.066C10.7478 8.786 11.1958 8.394 11.9238 8.394C12.6378 8.394 13.2818 8.912 13.2818 9.682C13.2818 10.396 12.6938 10.97 11.5458 10.97H10.7618C10.5938 10.97 10.4958 11.068 10.4958 11.25V12.132C10.4958 12.3 10.6078 12.412 10.7618 12.412H11.5458C12.8058 12.412 13.4778 13.07 13.4778 13.798C13.4778 14.568 12.7498 15.114 11.9938 15.114C11.1398 15.114 10.3138 14.414 10.2718 14.358C10.1598 14.246 10.0338 14.246 9.90778 14.358L9.19378 15.044C9.10978 15.142 9.09578 15.296 9.17978 15.394Z',
    'M9.05538 15.414C8.90138 15.414 8.77538 15.274 8.77538 15.134V14.224C8.77538 14.028 8.83138 13.874 8.95738 13.734L13.9134 7.714C14.0114 7.602 14.0954 7.56 14.2774 7.56H14.9634C15.1174 7.56 15.2434 7.686 15.2434 7.84V13.916H16.3914C16.5594 13.916 16.6714 14.028 16.6714 14.168V15.134C16.6714 15.274 16.5594 15.414 16.3914 15.414H15.2434L15.2574 17.22C15.2574 17.374 15.1314 17.5 14.9774 17.5H13.7874C13.6474 17.5 13.5074 17.374 13.5074 17.22V15.414H9.05538ZM10.7074 13.93V13.958H13.5634V10.472H13.5354L10.7074 13.93Z',
    'M9.35524 16.45C9.15924 16.226 9.14524 16.128 9.32724 15.96L10.0412 15.246C10.1392 15.162 10.2792 15.134 10.3912 15.232C10.7972 15.568 11.3852 16.044 12.2532 16.044C13.2332 16.044 13.9192 15.246 13.9192 14.336C13.9192 13.454 13.3172 12.726 12.1832 12.726H9.95724C9.66324 12.726 9.50924 12.46 9.50924 12.222V7.966C9.50924 7.826 9.62124 7.7 9.78924 7.7H14.3392C14.4932 7.7 14.6192 7.826 14.6192 7.966V8.946C14.6192 9.1 14.4932 9.226 14.3392 9.226H11.1332V11.144H12.3792C14.4232 11.144 15.6832 12.46 15.6832 14.336C15.6832 16.142 14.1852 17.64 12.1972 17.64C10.6432 17.64 9.70524 16.884 9.35524 16.45Z',
    'M9.53587 13.664C9.53587 9.45 13.1059 7.854 14.3379 7.448C14.5479 7.392 14.6879 7.462 14.7439 7.644L15.0799 8.498C15.1499 8.666 15.0939 8.834 14.8139 8.918C14.1419 9.128 12.0979 10.024 11.6499 11.62C11.8879 11.466 12.6159 11.214 13.2879 11.214C14.7299 11.214 16.2279 12.278 16.2279 14.336C16.2279 16.156 14.7439 17.64 12.9659 17.64C10.7119 17.64 9.53587 15.834 9.53587 13.664ZM12.9519 16.03C13.8619 16.03 14.6039 15.33 14.6039 14.308C14.6039 13.342 13.8619 12.614 12.9379 12.614C11.9859 12.614 11.4259 13.356 11.2719 13.874C11.2719 14.868 11.7899 16.03 12.9519 16.03Z',
  ];
}
