<ng-container *ngIf="loading$ | async">
	<div class="new-modal">
		<div class="modal-dialog">
			<div class="loading-modal-content">
				<div>
					<br />
					<br />
					<br />
					<p class="modal-text">Loading...</p>
					<br />
                    <!-- TODO: add fontawesome? or use the new mobile app modal? -->
					<!-- <div style="height:150px">
						<i class="modal-cog fa fa-cog fa-spin fa-4x"></i>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</ng-container>
