<div [attr.id]="'form-' + question.key" class="btn__container">
  <button
    [attr.id]="question.key"
    type="button"
    (click)="eventEmitter.emit(question.action)"
    class="btn btn__confirm"
  >
    {{ question.actionLabel }}
  </button>
</div>
