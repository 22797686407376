import { FormGroup, AbstractControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';

export class CustomFormGroup extends FormGroup {

	private _formType: string;
	public get formType(): string {
		return this._formType;
	}
	public set formType(v: string) {
		this._formType = v;
	}

	private _submitted: boolean;
	public get submitted(): boolean {
		return this._submitted;
	}
	public set submitted(v: boolean) {
		this._submitted = v;
	}

	constructor(
		control: { [Key: string]: AbstractControl; },
		validator?: ValidatorFn,
		asyncValidator?: AsyncValidatorFn) {
		super(control, validator, asyncValidator);
	}
}
