import { Component, Input, OnInit } from '@angular/core';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionBase } from '../../models/questions/question-base.model';
import { Observable, filter, map, of, startWith, switchMap } from 'rxjs';
import { Dependency } from '../../models/dependency.model';
import { DependenciesService } from '../../services/dependencies.service';
import { KeyValue } from '@angular/common';
import { QuestionRepeater } from '../../models/questions/question-repeater.model';

@Component({
  selector: 'app-dynamic-repeater',
  templateUrl: './dynamic-repeater.component.html',
  styleUrls: ['./dynamic-repeater.component.sass']
})
export class DynamicRepeaterComponent implements OnInit {
	@Input() question: QuestionRepeater;
	@Input() form: CustomFormGroup;

  childQuestions: QuestionBase<any>[];
  repeatAmount: number;

	showPerValueDependency$: Observable<number>;

  /**
   * Repeats a set of questions for a specified amount of time
   */
  constructor(private dependenciesService: DependenciesService) {}

  ngOnInit(): void {
    this.childQuestions = this.question.children as QuestionBase<any>[];

    const hasShowPerCountDependency = this.question.dependencies?.find(x => x.type === 'show-per-value');

    this.showPerValueDependency$ = this.dependenciesService.receive().pipe(
			filter(x => x.key === hasShowPerCountDependency?.group[0].key),
			switchMap((dependency: Dependency) => of(Number(dependency.value))),
			startWith(hasShowPerCountDependency ? 0 : this.repeatAmount),
		);
  }

  
}
