<section id="appHeader">
  <div
    class="container NW20-container"
    *ngIf="(headerDisabled$ | async) === false"
  >
    <div class="row">
      <h2 class="form__title">
        {{ qs.formTitle }}
      </h2>
    </div>
  </div>
  <app-steps [steps]="steps"></app-steps>
</section>

<section id="appContent" class="application__content">
  <div class="form__container-application">
    <form
      [formGroup]="form"
      *ngIf="questions$ | async as questions"
      class="form-horizontal form__container-application-form"
    >
      <ng-container
        *ngFor="let question of questions"
        [ngSwitch]="question.controlType"
      >
        <ng-container *ngIf="question.step === formService.currentStep">
          <app-dynamic-form-question-group
            *ngSwitchCase="'group'"
            [question]="question"
            [form]="form"
            (eventEmitter)="eventHandler($event)"
          >
          </app-dynamic-form-question-group>

          <ng-container *ngSwitchDefault>
            <app-dynamic-form-question
              [question]="question"
              [form]="form"
              (eventEmitter)="eventHandler($event)"
            ></app-dynamic-form-question>
          </ng-container>
        </ng-container>
      </ng-container>
    </form>
    <p id="invalidForm" style="color: red"></p>
    <p id="invalidHiddenControls" style="color: red"></p>
  </div>
</section>
