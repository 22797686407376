import { Component } from '@angular/core';
import { EMPTY, Observable, catchError } from 'rxjs';
import { DeathClaim } from 'src/app/models/death-claim.interface';
import { AuthService } from 'src/app/services/auth.service';
import { DeathClaimsService } from 'src/app/services/death-claims.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent {
  constructor(
    private deathClaimsService: DeathClaimsService,
    private authService: AuthService
  ) {}

  public errorMessage = '';

  public deathClaims$: Observable<DeathClaim[]> = this.deathClaimsService
    .getClaims()
    .pipe(
      catchError((error: string) => {
        this.errorMessage = error;
        return EMPTY;
      })
    );

  get loginDisplay() {
    return this.authService.loginDisplay;
  }

  login() {
    this.authService.login();
  }

  register() {
    this.authService.register();
  }  
}
