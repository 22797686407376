import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IDynamicFormfield } from '../../../models/dynamic-formfield.interface';
import { CustomFormGroup } from '../../../models/form/custom-form-group.model';
import { QuestionDropdown } from '../../../models/questions/question-dropdown.model';
import { BehaviorSubject } from 'rxjs';
import { KeyValue } from '@angular/common';

@Component({
	selector: 'app-dynamic-multiselect-dropdown',
	templateUrl: 'dynamic-multiselect-dropdown.component.html',
	styleUrls: ['dynamic-multiselect-dropdown.component.css']
})
export class DynamicMultiselectDropdownComponent implements OnInit, IDynamicFormfield {
	@Input() question: QuestionDropdown;
	@Input() form: CustomFormGroup;

	showDropDown: boolean = false;
	checkboxOptions: {keyvalue: KeyValue<string, string>, isChecked: boolean}[] = [];
	checkedDisplayList$ = new BehaviorSubject("");

	ngOnInit(): void {
		// initialise selected options
		this.question.options.forEach((option: KeyValue<string, string>) => {
			this.question.value?.find(x => x.key === option.key) ? 
				this.checkboxOptions.push({keyvalue: option, isChecked: true}) :
				this.checkboxOptions.push({keyvalue: option, isChecked: false})
		});

		this.updateValues();
	}

	toggleOption(option: {keyvalue: KeyValue<string, string>, isChecked: boolean}): void {
		//if value already exists then remove else add
		const index = this.checkboxOptions.findIndex(x => x.keyvalue.key === option.keyvalue.key);
		//reverse the isChecked
		this.checkboxOptions[index].isChecked = !this.checkboxOptions[index].isChecked;

		this.updateValues();
	}

	updateValues(): void {
		let checkedValues = this.checkboxOptions.filter(x => x.isChecked);
		this.checkedDisplayList$.next(
			checkedValues.map(x =>x.keyvalue.key).join(', ')
		);

		//add all current values to the control
		let control = this.form.controls[this.question.key] as CustomFormGroup;
		control.patchValue(checkedValues);
	}
}
