import { Component, OnInit, Input } from '@angular/core';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { QuestionText } from '../../models/questions/question-text.model';

@Component({
	selector: 'app-dynamic-text',
	templateUrl: './dynamic-text.component.html',
	styleUrls: ['./dynamic-text.component.css']
})
export class DynamicTextComponent implements OnInit {
	@Input() question: QuestionText;
	@Input() form: CustomFormGroup;

	ngOnInit() {
		// Remove any unnecessary whitespace from indenting in C#
		this.question.value = this.question.value?.replace(/\s+/g, ' ');
	}
}
