<div [ngClass]="question.type === 'hidden' ? '' : 'form-group NW20-form-group'" *ngIf="!(hideQuestion$ | async)">
    <!-- <div *ngIf="question.hasSeparator" class="">
    <div class="">
      <hr class="separator" />
    </div> -->

  <div>
    <!--<div *ngIf="showLabel" class="">-->
    <!--<div *ngIf="showLabel">
            <app-dynamic-validation [form]="form" [key]="question.key" [tooltip]="question.tooltip"></app-dynamic-validation>
        </div>-->
    <ng-container *ngIf="showLabel">
      <app-dynamic-label [question]="question" [showLabel]="showLabel"></app-dynamic-label>
    </ng-container>

    <!-- <app-dynamic-tooltip [form]="form" [key]="question.key" [tooltip]="question.tooltip"></app-dynamic-tooltip> -->
    <!--test starts
                <div class="help-tip help-tip-noErrorMessage NW20-help-tip">
                    <p>
                        Due to setup time, the earliest possible direct debit collection date is 8th October 2020.
                    </p>
                </div>
            test ends -->
            <ng-container *ngIf="question.multipleValues && question.multipleValues.length > 0 && question.controlType !='declaration'">
      <!-- <app-dynamic-multi-text [content]="question.multipleValues"></app-dynamic-multi-text> -->
    </ng-container>
    <!--<div [ngSwitch]="question.controlType" [ngClass]="{'col-xs-12': true, 'col-sm-7': !question.longLabel, 'col-sm-2': question.longLabel, 'col-sm-pull-3': true}">-->
    <div [ngSwitch]="question.controlType">
            <app-dynamic-textbox *ngSwitchCase="'textbox'" [question]="question" [form]="form"></app-dynamic-textbox>
            <app-dynamic-dropdown *ngSwitchCase="'dropdown'" [question]="question" [form]="form"></app-dynamic-dropdown>
            <app-dynamic-checkbox *ngSwitchCase="'checkbox'" [question]="question" [form]="form"></app-dynamic-checkbox>
            <app-dynamic-radio *ngSwitchCase="'radio'" [question]="question" [form]="form"></app-dynamic-radio>
      <!-- <app-dynamic-isa-transfer-radio *ngSwitchCase="'isa-transfer-radio'" [question]="question" [form]="form"></app-dynamic-isa-transfer-radio> -->
            <app-dynamic-button *ngSwitchCase="'button'" [question]="question" [form]="form" (eventEmitter)="eventEmitter.emit($event)"></app-dynamic-button>
            <app-dynamic-table *ngSwitchCase="'table'" [question]="question" [form]="form"></app-dynamic-table>
            <app-dynamic-text *ngSwitchCase="'text'" [question]="question" [form]="form"></app-dynamic-text>
            <app-dynamic-repeater *ngSwitchCase="'repeater'" [question]="question" [form]="form"></app-dynamic-repeater>
      <!-- <a *ngSwitchCase="'link'" [attr.href]="question.value">{{question.action}}</a> -->
      <!-- <app-dynamic-occupation *ngSwitchCase="'occupation'" [question]="question" [form]="form"></app-dynamic-occupation> -->
      <!-- <app-dynamic-sports-and-pastimes *ngSwitchCase="'sports-and-pastimes'" [question]="question" [form]="form"></app-dynamic-sports-and-pastimes> -->
      <!-- <app-dynamic-document-upload *ngSwitchCase="'document-upload'" [question]="question" [form]="form"></app-dynamic-document-upload> -->
      <!-- <app-dynamic-country-tax *ngSwitchCase="'country-tax'" [question]="question" [form]="form"></app-dynamic-country-tax> -->

    </div>
    <!--<div class="" [ngSwitch]="question.controlType">-->
    <div [ngSwitch]="question.controlType">
            <app-dynamic-information *ngSwitchCase="'information'" [question]="question" [form]="form"></app-dynamic-information>
            <app-dynamic-document *ngSwitchCase="'document'" [question]="question" [form]="form"></app-dynamic-document>

    </div>
    <!--<div class="">-->
    <div>
      <ng-container [ngSwitch]="question.controlType">
                <app-dynamic-address *ngSwitchCase="'address'" [question]="question" [form]="form"></app-dynamic-address>
                <app-dynamic-direct-debit *ngSwitchCase="'directdebit'" [question]="question" [form]="form"></app-dynamic-direct-debit>
        <!-- <app-dynamic-tele-interview *ngSwitchCase="'teleinterview'" [question]="question" [form]="form"></app-dynamic-tele-interview> -->
        <!-- <app-dynamic-bmi *ngSwitchCase="'bmi'" [question]="question" [form]="form"></app-dynamic-bmi> -->
        <!-- <app-dynamic-extra-medical-info *ngSwitchCase="'extra-medical-info'" [question]="question" [form]="form"></app-dynamic-extra-medical-info> -->
                <app-dynamic-header *ngSwitchCase="'header'" [question]="question" [form]="form"></app-dynamic-header>
        <!-- <app-dynamic-ctf-dob *ngSwitchCase="'ctf-dob'" [question]="question" [form]="form"></app-dynamic-ctf-dob> -->
        <!-- <app-dynamic-plan-length *ngSwitchCase="'plan-length'" [question]="question" [form]="form"></app-dynamic-plan-length> -->
        <!-- <app-dynamic-summary *ngSwitchCase="'summary'"></app-dynamic-summary> -->
        <!-- <app-claims-summary *ngSwitchCase="'claims-summary'" [question]="question" [form]="form"></app-claims-summary> -->
        <!-- <app-dynamic-declaration *ngSwitchCase="'declaration'" [question]="question" [form]="form"></app-dynamic-declaration> -->
      </ng-container>
    </div>
    <!--starts-->
    <div *ngIf="showLabel">
            <app-dynamic-validation [form]="form" [key]="question.key" [label]="question.label" [tooltip]="question.tooltip"></app-dynamic-validation>
    </div>
    <!--ends-->
  </div>
</div>