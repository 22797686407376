<div class="container__upload-document">
  <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
  <ngx-file-drop
    (onFileDrop)="dropped($event)"
    [multiple]="false"
    [accept]="acceptedFormats"
    [directory]="false"
    dropZoneClassName="documentUploadContainer"
  >
    <ng-template
      ngx-file-drop-content-tmp
      let-openFileSelector="openFileSelector"
    >
      <button class="btn btn-small primary btn-upload" (click)="openFileSelector()">
        <i class="fas fa-cloud-upload-alt"></i>
        Upload File
      </button>
    </ng-template>
  </ngx-file-drop>
</div>

<div *ngIf="(documentUploadResponse$ | async) as documentUploadResponse">
  <p *ngIf="!documentUploadResponse">
    An error occured, please try uploading your document again.
  </p>
</div>