import { Component, Input } from '@angular/core';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';

@Component({
  selector: 'app-dynamic-tooltip',
  templateUrl: './dynamic-tooltip.component.html',
  styleUrls: ['./dynamic-tooltip.component.css']
})
export class DynamicTooltipComponent {
	@Input() form: CustomFormGroup;
	@Input() key: string;
	@Input() tooltip?: string;
  
  public hasTooltip(): boolean {
		let result = false;
		if (this.tooltip != null) {
			if (this.tooltip.length > 0) {
				result = true;
			}
		}
		return result;
	}
}
