import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { PostcodeSearchModel } from './../models/form/postcode-search.model';
import { AddressModel } from './../models/form/address.model';
import { AppConstantsService } from './app-constants.service';
import { Observable, map } from 'rxjs';

@Injectable()
export class PostcodeService {

	private actionUrl: string;
	private headers: Headers;

	constructor(private _http: HttpService, private _configuration: AppConstantsService) {

		this.actionUrl = _configuration.Server;

		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('Accept', 'application/json');
	}

	public Find = (searchTerm: string, preferredLanguage: string, filter: string): Observable<Array<PostcodeSearchModel>> => {

		let params: string = [
			`SearchTerm=${searchTerm}`,
			`PreferredLanguage=${preferredLanguage}`,
			`Filter=${filter}`
		].join('&');

		let queryUrl = `${this.actionUrl}/utilities/findaddresses?${params}`;

		return this._http.get<PostcodeSearchModel[]>(queryUrl).pipe(
			map(response =>	
				JSON.parse(response.toString()) as PostcodeSearchModel[]
			)
		);
	}

	public GetById = (id: string, preferredLanguage: string): Observable<AddressModel> => {

		let params: string = [
			`Id=${id}`,
			`PreferredLanguage=${preferredLanguage}`
		].join('&');

		let queryUrl = `${this.actionUrl}/utilities/retrieveaddressbyid?${params}`;

		return this._http.get<AddressModel>(queryUrl).pipe(
			map(response => {
				let jsonResult = JSON.parse(response.toString());
				return new AddressModel(
					jsonResult[0].Line1,
					jsonResult[0].Line2,
					jsonResult[0].PostTown,
					jsonResult[0].County,
					jsonResult[0].Postcode
				)
			})
		);
	}
}
