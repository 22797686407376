import { Component, Input, OnInit } from '@angular/core';
import { IDynamicFormfield } from '../../models/dynamic-formfield.interface';
import { AddressModel } from '../../models/form/address.model';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { PostcodeSearchModel } from '../../models/form/postcode-search.model';
import { QuestionAddress } from '../../models/questions/question-address.model';
import { PostcodeService } from '../../services/postcode.service';

@Component({
	selector: 'app-dynamic-address',
	templateUrl: 'dynamic-address.component.html',
	styleUrls: ['dynamic-address.component.css'],
	providers: [PostcodeService]
})
export class DynamicAddressComponent implements OnInit, IDynamicFormfield {
	@Input() question: QuestionAddress;
	@Input() form: CustomFormGroup;
	
	searchResults: Array<PostcodeSearchModel>;
	showFullAddress = false;
	invalidPostcode = false;
	isReadOnly = false;
	showAddressSearch = true; 

	constructor(private _postcodeService: PostcodeService) { }

	ngOnInit() {
		// If the question is initialized with a value
		// parse the value and update the form with it
		let addressObject = this.form.controls[this.question.key].value;
		let isEmptyAddressObject = Object.keys(addressObject).every((k) => addressObject[k] === '' || addressObject[k] === null);
		let isEmptyQuestionObject = this.question.value ? Object.values(this.question.value).every((k) => k === '' || k === null) : null;

		if (!isEmptyQuestionObject || !isEmptyAddressObject) {
			let questionValue = isEmptyAddressObject ? this.question.value : addressObject;
			this.form.controls[this.question.key].setValue(questionValue);
			this.showFullAddress = true;
			if (this.question.type === 'readonly'){
				this.isReadOnly = true;
				this.showAddressSearch = false;
			}
		}
	}

	get control() { return this.form.get(this.question.key); }

	// Called when search button is clicked
	search(postcode: string) {
		// Check they've entered a valid postcode
		if (this.checkPostcodeValidity(postcode.toUpperCase())) {
			this._postcodeService.Find(postcode, 'English', 'None').subscribe(response => {
				// Show results if we got a response
				if (response[0] !== undefined) {
					this.invalidPostcode = false;
					this.searchResults = response;
					this.searchResults.unshift(new PostcodeSearchModel("0","Select address",""))
				} else {
					// If we got no results, make them enter
					// the address manually
					this.showInvalidChildFields();
				}
			}, () => {
				// If the postcode API returns an error,
				// make them enter the address manually
				this.showInvalidChildFields();
			});
		} else {
			// If the postcode they've entered is invalid
			// make them enter the address manually
			this.showInvalidChildFields();
		}
		this.form.controls[this.question.key].updateValueAndValidity();
	}

	// Called when an option in the dropdown is picked
	select(event: Event) {
		let id = (event.target as HTMLInputElement).value;
				// Hit postcode API to get full address info
		this._postcodeService.GetById(id, 'English').subscribe(response => {
			// Update form value with response we got
			this.form.controls[this.question.key].setValue(response);
			// Show the full address
			this.showFullAddress = true;
		});
	}

	private checkPostcodeValidity(postcode: string) {
		// http://stackoverflow.com/a/26887154
		// GA 18-06-2019 Allow zero or one space in postcode search
		let regexp =`^(GIR 0AA)|((([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) ?[0-9][A-Z]{2})$`;
		return new RegExp(regexp).test(postcode);
	}

	private showInvalidChildFields() {
		// Used to show invalid postcode message
		this.invalidPostcode = true;
		// Show the full address so user can enter manually
		this.showFullAddress = true;
		// Wipe whatever we've got store in the form
		this.control?.setValue(new AddressModel());

		this.form.get(this.question.key + '.line1')?.markAllAsTouched();
		this.form.get(this.question.key + '.city')?.markAllAsTouched();
		this.form.get(this.question.key + '.postcode')?.markAllAsTouched();

	}
}
