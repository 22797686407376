import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { ValidationService } from '../../services/validation.service';
import { Observable, of, switchMap } from 'rxjs';
import { ErrorModel } from '../../models/form/error.model';

@Component({
	selector: 'app-dynamic-validation',
	templateUrl: './dynamic-validation.component.html',
	styleUrls: ['./dynamic-validation.component.css']
})
export class DynamicValidationComponent implements OnInit {
	@Input() form: CustomFormGroup;
	@Input() key: string;
	@Input() label: string;
	@Input() tooltip?: string;

	subComponent: string;
	serverError = false;

	constructor(private validationService: ValidationService) { }

	serverError$: Observable<string | null> = this.validationService.getErrors().pipe(
		switchMap((errors: ErrorModel[]) => {

			if (this.validationService.hasTriedToSubmit() || this.getComponent().dirty) {
				let questionErrors = errors.filter(x => x.Name.toLowerCase() === this.key?.toLowerCase());

				if (questionErrors && questionErrors.length > 0) {
					return of(questionErrors[0].Error);
				}
			}
			return of(null);
		})
	);

	get control() { return this.form.get(this.key); }

	ngOnInit() {
		if (this.key) {
			let keyComponents = this.key.split('.');
			if (keyComponents.length > 1) {
				this.subComponent = keyComponents[1];
			}
		}

		this.getErrors();
	}

	private getErrors() {
		this.validationService.getErrors().subscribe(errors => {
			this.serverError = false;

			errors.forEach(error => {
				if (this.key && error.Name.toLowerCase() === this.key.toLowerCase()) {
					if (this.validationService.hasTriedToSubmit() || this.getComponent().dirty) {
						this.serverError = true;
					}
					else {
						this.serverError = false;
					}
				}
			});
		});
	}

	private getComponent(): AbstractControl {
		if (this.subComponent) {
			let group = this.form.controls[this.key.split('.')[0]] as CustomFormGroup;
			return group.controls[this.subComponent];
		}
		return this.form.controls[this.key];
	}

	protected getErrorListByKeys(errorObject : any) {
		return Object.keys(errorObject);
	  }

	// public hasTooltip(): boolean {
	// 	let result = false;
	// 	if (this.tooltip != null) {
	// 		if (this.tooltip.length > 0) {
	// 			result = true;
	// 		}
	// 	}
	// 	return result;
	// }
}
