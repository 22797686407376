import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()

export class LoqateBankDetailsService {

  constructor(private http: HttpService) { }

  private addressyApiUrl = 'https://api.addressy.com';
  private addressyApiBankKey = 'DU74-CE25-FX62-EE65';


  bankVerification(sortCode: string, accountNumber: string): Observable<LoqateBankResponse> {
    var url = this.addressyApiUrl + "/BankAccountValidation/Interactive/Validate/v2.00/json3.ws?" +
    "&Key=" + this.addressyApiBankKey +
    "&AccountNumber=" + accountNumber +
    "&SortCode=" + sortCode;

    return this.http.get(url).pipe(
      map((response: any) => this.handleLoqateBankResponse(response))
    );
  }

  private handleLoqateBankResponse(response: any) : LoqateBankResponse {
    let loqateBankResponse = new LoqateBankResponse();
    loqateBankResponse.successful = false;
    loqateBankResponse.error = new LoqateBankError();
    
    if (response.Items.length == 1 && typeof(response.Items[0].Error) != "undefined") {
      loqateBankResponse.error = this.handleLoqateBankErrorMessage(response.Items[0].Error);
      return loqateBankResponse;
    } 
    
    if (response.Items.length == 0) {
      loqateBankResponse.error.message = "No banks were found for this sort code and account number.";
      return loqateBankResponse;
    } 

    if (response.Items[0].IsCorrect === false) {
        loqateBankResponse.error = this.handleLoqateBankErrorMessage(response.Items[0].StatusInformation);
        return loqateBankResponse;
    } 

    loqateBankResponse.successful = true;  
    return loqateBankResponse;
  }

  private handleLoqateBankErrorMessage(errorKey: string): LoqateBankError {
    switch (errorKey) {
        case "1001":
            return {
                field: 'BankDetails.ddSortCode',
                message: "Your sort code is required"
            } as LoqateBankError

        case "1002":
            return {
                field: 'BankDetails.ddSortCode',
                message: "Your sort code is invalid"
            } as LoqateBankError

        case "1003":
            return {
                field: 'BankDetails.ddAccountNumber',
                message: "Your account number is required"
            } as LoqateBankError

        case "1004":
            return {
                field: 'BankDetails.ddAccountNumber',
                message: "Your account number is invalid"
            } as LoqateBankError

        case "InvalidAccountNumber":
            return {
                field: 'BankDetails.ddAccountNumber',
                message: "Your account number is invalid"
            } as LoqateBankError

        case "UnknownSortCode":
            return {
                field: 'BankDetails.ddSortCode',
                message: "Your sort code is invalid"
            } as LoqateBankError
                
    
        default:
          return {
              field: 'BankDetails.ddSortCode',
              message: "An error has occured please try again."
          } as LoqateBankError
    }
  }

}

export class LoqateBankError {
  field: string;
  message: string;
}


export class LoqateBankResponse {
  successful: boolean;
  error: LoqateBankError;
}