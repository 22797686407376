import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.sass']
})
export class NavigationComponent {

  constructor(private authService: AuthService) {}


  get loginDisplay() {
    return this.authService.loginDisplay;
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }
}
