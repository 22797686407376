import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './components/dynamic-form-question/dynamic-form-question.component';
import { DynamicHeaderComponent } from './components/dynamic-header/dynamic-header.component';
import { DynamicTextComponent } from './components/dynamic-text/dynamic-text.component';
import { DynamicFormsRoutingModule } from './dynamic-forms.routing';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ValidationService } from './services/validation.service';
import { AppConstantsService } from './services/app-constants.service';
import { DependenciesService } from './services/dependencies.service';
import { FormService } from './services/form.service';
import { GaTrackingService } from './services/ga-tracking.service';
import { HttpService } from './services/http.service';
import { LoqateBankDetailsService } from './services/loqate-bank-details.service';
import { ModalService } from './services/modal.service';
import { PostcodeService } from './services/postcode.service';
import { QuestionControlService } from './services/question-control.service';
import { QuestionService } from './services/question.service';
import { DynamicAddressComponent } from './components/dynamic-address/dynamic-address.component';
import { DynamicButtonComponent } from './components/dynamic-button/dynamic-button.component';
import { DynamicCheckboxComponent } from './components/dynamic-checkbox/dynamic-checkbox.component';
import { DynamicDirectDebitComponent } from './components/dynamic-direct-debit/dynamic-direct-debit.component';
import { DynamicDropdownComponent } from './components/dynamic-dropdown/dynamic-dropdown.component';
import { DynamicInformationComponent } from './components/dynamic-information/dynamic-information.component';
import { DynamicMultiTextComponent } from './components/dynamic-multi-text/dynamic-multi-text.component';
import { DynamicRadioComponent } from './components/dynamic-radio/dynamic-radio.component';
import { DynamicTooltipComponent } from './components/dynamic-tooltip/dynamic-tooltip.component';
import { DynamicValidationComponent } from './components/dynamic-validation/dynamic-validation.component';
import { DynamicTextboxComponent } from './components/dynamic-textbox/dynamic-textbox.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DynamicFormQuestionGroupComponent } from './components/dynamic-form-question-group/dynamic-form-question-group.component';
import { DynamicLabelComponent } from './components/dynamic-label/dynamic-label.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DynamicMultiselectDropdownComponent } from './components/dynamic-dropdown/dynamic-multiselect-dropdown/dynamic-multiselect-dropdown.component';
import { DynamicDocumentComponent } from './components/dynamic-document/dynamic-document.component';
import { DynamicRepeaterComponent } from './components/dynamic-repeater/dynamic-repeater.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AppConfigModule } from '../app-settings/app-config.module';
import { StepsComponent } from './components/steps/steps.component';

@NgModule({
  declarations: [
    //components
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    DynamicFormQuestionGroupComponent,
    DynamicHeaderComponent,
    DynamicTextComponent,
    DynamicAddressComponent,
    DynamicButtonComponent,
    DynamicCheckboxComponent,
    DynamicDirectDebitComponent,
    DynamicDropdownComponent,
    DynamicInformationComponent,
    DynamicMultiTextComponent,
    DynamicRadioComponent,
    DynamicTextComponent,
    DynamicTextboxComponent,
    DynamicTooltipComponent,
    DynamicValidationComponent,
    DynamicLabelComponent,
    DynamicTableComponent,
    DynamicMultiselectDropdownComponent,
    DynamicDocumentComponent,
    DynamicRepeaterComponent,
    StepsComponent,

    //directives
    ClickOutsideDirective,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    DynamicFormsRoutingModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,

    // shepherds modules
    AppConfigModule,
  ],
  providers: [
    AppConstantsService,
    DependenciesService,
    FormService,
    GaTrackingService,
    HttpService,
    LoqateBankDetailsService,
    ModalService,
    PostcodeService,
    QuestionControlService,
    QuestionService,
    ValidationService,
    DatePipe,
    provideNgxMask(),
  ],
})
export class DynamicFormsModule {}
