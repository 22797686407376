
<ng-container *ngIf="document">
    <div class="informationSection NW20-informationSection">
        <img [src]="document.imageUrl" alt="pdf icon" class="NW20-pdfIcon" />
        <a [href]="document.documentUrl"class="appQuoteDownload NW20-appQuoteDownload" target="_blank">
            {{document.name}}
        </a>
    </div>
</ng-container>

