import { KeyValue } from "@angular/common";
import { Table } from "../form/table.model";

export class QuestionBase<T> {
	controlType: string;
	value: T | undefined;
	key: string;
	label: string;
	longLabel: boolean;
	order: number;
	step: number;
	validators?: KeyValue<string,string>[];
	validatorDependency?: string;
	hide: boolean;
	tooltip?: string;
	multipleValues?: any[];
	dependencies?: QuestionDependency[];
	hasSeparator: boolean;
	type: string;
	action: string;
	actionLabel: string;
	mask: string[];
	options: {key: string, value: string}[];
	children?: QuestionBase<T>[];
	classes?: string[];
	table?: Table;

	constructor(options: {
		controlType?: string,
		value?: T,
		key?: string,
		label?: string,
		longLabel?: boolean
		order?: number,
		step?: number,
		hide?: boolean,
		validators?: KeyValue<string,string>[],
		validatorDependency?: string,
		tooltip?: string,
		multipleValues?: any[],
		dependencies?: QuestionDependency[],
		hasSeparator?: boolean,
		type?: string;
		action?: string;
		actionLabel?: string;
		mask?: string[];
		options?: {key: string, value: string}[];
		children?: QuestionBase<T>[];
		classes?: string[];
		table?: Table;
	} = {}) {
		this.controlType = options.controlType!;
		this.key = options.key || '';
		this.label = options.label || '';
		this.longLabel = options.longLabel || false;
		this.order = options.order === undefined ? 1 : options.order;
		this.step = options.step || 0;
		this.hide = options.hide || false;
		this.validators = options.validators || [];
		this.validatorDependency = options.validatorDependency;
		this.tooltip = options.tooltip;
		this.multipleValues = options.multipleValues;
		this.dependencies = options.dependencies;
		this.hasSeparator = options.hasSeparator || false;
		this.type = options.type || '';
		this.action = options.action || '';
		this.actionLabel = options.actionLabel || '';
		this.mask = options.mask || [];
		this.options = options.options || [];
		this.children = options.children || undefined;
		this.classes = options.classes || [];
		this.table = options.table || undefined;

		try {
			if (options.value) {
				this.value = JSON.parse(options.value.toString()) as T;
			}
		} catch (error) {
			this.value = options.value;
		}
	}
}

export class QuestionDependency {
	group: {key: string, value: string}[];
	type: string;
}
