import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { HttpOptions } from '../models/http-options.model';

@Injectable()
export class HttpService {

	constructor(private http: HttpClient) {}

    get<T>(url: string, options?: HttpOptions) {
        return this.http.get<T>(url, options as any).pipe(
            map((response) => {
                return <T>response;
            })
        );
    }

    post<T>(url: string, body: any, options?: HttpOptions) {
        return this.http.post<T>(url, body, options as any).pipe(
            map((response) => {
                return <T>response;
            })
        );
    }

    put<T>(url: string, body: any, options?: HttpOptions) {
        return this.http.put<T>(url, body, options as any).pipe(
            map((response) => {
                return <T>response;
            })
        );
    }

    delete<T>(url: string, options?: HttpOptions) {
        return this.http.delete<T>(url, options as any).pipe(
            map((response) => {
                return response;
            })
        );
    }
	private tryParseResponse<T>(response: Response) {
		try {
			return response.json();
		} catch (error) {
			return null;
		}
	}
}
