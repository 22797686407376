import { Component, OnInit, Input } from '@angular/core';
import { QuestionLabel } from '../../models/questions/question-label.model';
import { MultiTextModel } from '../../models/form/multi-text.model';

@Component({
	selector: 'app-dynamic-label',
	templateUrl: './dynamic-label.component.html',
	styleUrls: ['./dynamic-label.component.css']
})
export class DynamicLabelComponent implements OnInit {
	@Input() question: QuestionLabel;
	@Input() showLabel: boolean;

	multitext: MultiTextModel[] | null;

	constructor() { }

	ngOnInit() {

		try {
			var multipleValuesString = this.question.multipleValues?.toString();
			if(multipleValuesString) {
				this.multitext = JSON.parse(multipleValuesString) as MultiTextModel[];
			} else {
				this.multitext = null;
			}
			
		} catch (error) {
			this.multitext = null;
		}
	}
}
