import { QuestionBase, QuestionDependency } from './../models/questions/question-base.model';
import { Injectable } from '@angular/core';
import { Dependency } from './../models/dependency.model';
import { CustomFormGroup } from '../models/form/custom-form-group.model';
import { BehaviorSubject, Subject, shareReplay } from 'rxjs';
import { IDynamicFormfield } from '../models/dynamic-formfield.interface';
import { KeyValue } from '@angular/common';

@Injectable()
export class DependenciesService {
	private _dependencySubject: Subject<Dependency> = new BehaviorSubject<Dependency>(new Dependency());
	private initialiseComponents = true;

	constructor() { }

	broadcastDependency(dependency: Dependency) {
		this._dependencySubject.next(dependency);
	}

	broadcast(formfield: IDynamicFormfield, value: string) {
		let dependency = new Dependency(formfield.question.key, value);
		this.broadcastDependency(dependency);
	}

	receive() {
		return this._dependencySubject.asObservable().pipe(shareReplay());
	}

	handleRepeater(questionDependency: QuestionDependency, dependenciesCurrentValues: Dependency[]) {
		let shouldHide = false;
		switch (questionDependency.type) {
				case 'repeater-item':
				if (questionDependency.group[0].value == "")
				{
					shouldHide = false;
					break;
				}

				let dependencyValue = dependenciesCurrentValues.find(x => x.key === questionDependency.group[0].key)?.value ?? '-1';
				shouldHide = Number.parseInt(dependencyValue) >= Number.parseInt(questionDependency.group[0].value) ? false : true;
				break;
		}

		return shouldHide;
	}	

	handle(questionDependency: QuestionDependency, dependenciesCurrentValues: Dependency[]) {
		let dependenciesMatch = this.checkQuestionDependenciesMatch(questionDependency.group, dependenciesCurrentValues);

		//default show only when dependencies match?
		let shouldHide = !dependenciesMatch;
		switch (questionDependency.type) {
			case 'show':
				shouldHide = !dependenciesMatch;
				break;
			case 'hide':
				shouldHide = dependenciesMatch;
				break;
			case 'LumpSum':
				let lumpSumValue = dependenciesCurrentValues.find(x => x.key === questionDependency.group[0].key)?.value ?? '';
				shouldHide = Number.parseInt(lumpSumValue) >= 10000;
				break;
			case 'show-per-value':
				if (questionDependency.group[0].value == "")
				{
					shouldHide = false;
					break;
				}

				let dependencyValue = dependenciesCurrentValues.find(x => x.key === questionDependency.group[0].key)?.value ?? '-1';
				shouldHide = Number.parseInt(dependencyValue) >= Number.parseInt(questionDependency.group[0].value) ? false : true;
				break;
			case 'repeater-item':
				shouldHide = false;
				break;
		}

		return shouldHide;
	}
	
	private checkQuestionDependenciesMatch(dependencyTarget: KeyValue<string,string>[], currentDependencyValues: KeyValue<string,string>[]) {
		let dependenciesMatch = true;
		
		dependencyTarget.forEach((questionDependency: KeyValue<string, string>) => {

			let dependency = currentDependencyValues.find(x => x.key === questionDependency.key);

			if (!dependency || dependenciesMatch === false) {
				dependenciesMatch = false;
				return;
			}

			dependenciesMatch = dependency.value?.toString() === questionDependency.value;
		});

		return dependenciesMatch;
	}

	allowInitialiseComponents(value: boolean) {
		this.initialiseComponents = value;
	}

	setFormDependencies(formGroup: CustomFormGroup, formQuestions: QuestionBase<any>[]) {
		formQuestions.map(question => {
			let formValue = formGroup.controls[question.key].value;
			let dependency = new Dependency();
			dependency.key = question.key;
			dependency.value = formValue;
			this.broadcastDependency(dependency);
		});
	}
}
