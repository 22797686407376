<div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>

<section
  class="your-documents"
  *ngIf="rejectedDocuments$ | async as rejectedDocuments"
>
  <div
    class="your-documents__wrapper mt-4 sf-card"
    *ngIf="rejectedDocuments.length > 0"
  >
    <h1 class="header mb-3">Non-compliant documents</h1>
    <div class="your-documents__row">
      <div class="your-documents__info">
        <p>Document</p>
        <p>Comments</p>
      </div>
      <div>
        <div
          *ngFor="let document of rejectedDocuments"
          class="your-documents__upload"
        >
          <p class="your-documents__upload--info">Document</p>
          <p>
            {{ document.documentType }}
          </p>
          <p class="your-documents__upload--info">Comments</p>
          <p>
            {{ document.verifiedDetail }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="your-documents">
  <div
    class="your-documents__wrapper mt-4 sf-card"
    *ngIf="documents$ | async as documents; else noDocuments"
  >
    <h1 class="header mb-3">Required documents</h1>
    <div class="your-documents__row">
      <div class="your-documents__info">
        <h3>Document</h3>
        <h3>Description</h3>
      </div>
      <div>
        <div *ngFor="let document of documents" class="your-documents__upload">
          <h3 class="your-documents__upload--info">Document</h3>
          <p>
            {{ document.documentType }}
          </p>
          <h3 class="your-documents__upload--info">Description</h3>
          <p>
            {{ document.description }}
          </p>
          <div
            *ngIf="document.uploaded; else uploadDocument"
            class="your-documents__uploaded"
          >
            <div class="your-documents__uploaded--btn">
              <i class="fa-solid fa-circle-check"></i>File uploaded
            </div>
          </div>
          <ng-template #uploadDocument>
            <app-document-upload
              [claimId]="(claimId$ | async)!"
              [documentId]="document.id"
              (onUploaded)="loadDocuments.next($event)"
            ></app-document-upload>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #noDocuments>
  <h2>Required documents</h2>
  <div>
    <p>there are no documents for this claim</p>
  </div>
</ng-template>
