<div [formGroup]="form" [attr.id]="'form-' + question.key">
  <div formGroupName="{{ question.key }}" class="marginBtm30">
    <div>
      <div class="address-picker">
        <div>
          <label [attr.for]="question.key + '-postcode-search'">{{
            question.label
          }}</label>
          <input
            [attr.id]="question.key + '-postcode-search'"
            type="text"
            class="form__input form__input--postcode"
            placeholder="Postcode"
            #searchText
            autocomplete="postal-code"
          />
        </div>

        <input
          [attr.id]="question.key + '-search'"
          type="button"
          class="btn tertiary postcode-search"
          (click)="search(searchText.value)"
          value="Search"
          autocomplete="off"
        />
      </div>
      <div *ngIf="invalidPostcode">
        <p class="errorMessage">
          Invalid Postcode - Please enter address manually.
        </p>
      </div>
    </div>
    <div
      class="address__dropdown-row"
      *ngIf="searchResults && !invalidPostcode"
    >
      <div>
        <select
          [attr.id]="question.key + '-postcodeSearchResults'"
          class="form__input form__input--select-address"
          (change)="select($event)"
        >
          <option disabled selected>-- Please select an address --</option>
          <option *ngFor="let result of searchResults" [attr.value]="result.Id">
            {{ result.StreetAddress }}
          </option>
        </select>
      </div>
    </div>

    <div *ngIf="showFullAddress">
      <div>
        <div>
          <label [attr.for]="question.key + '-line1'">Address Line 1</label>
        </div>
        <div>
          <input
            [attr.id]="question.key + '-line1'"
            class="form__input"
            placeholder="Line 1"
            formControlName="line1"
            autocomplete="address-line1"
          />
        </div>

        <div>
          <app-dynamic-validation
            [form]="form"
            [key]="question.key + '.line1'"
            [label]="'Address Line 1'"
          ></app-dynamic-validation>
        </div>
      </div>
      <div>
        <div>
          <label [attr.for]="question.key + '-line2'">Address Line 2</label>
        </div>
        <div>
          <input
            [attr.id]="question.key + '-line2'"
            class="form__input"
            type="text"
            placeholder="Line 2"
            formControlName="line2"
            autocomplete="address-line2"
          />
        </div>

        <div>
          <app-dynamic-validation
            [form]="form"
            [key]="question.key + '.line2'"
            [label]="'Address Line 2'"
          ></app-dynamic-validation>
        </div>
      </div>
      <div>
        <div>
          <label [attr.for]="question.key + '-city'">City</label>
        </div>
        <div>
          <input
            [attr.id]="question.key + '-city'"
            class="form__input"
            type="text"
            placeholder="City"
            formControlName="city"
            autocomplete="off"
          />
        </div>

        <div>
          <app-dynamic-validation
            [form]="form"
            [key]="question.key + '.city'"
            [label]="'City'"
          ></app-dynamic-validation>
        </div>
      </div>
      <div>
        <div>
          <label [attr.for]="question.key + '-county'">County</label>
        </div>
        <div>
          <input
            [attr.id]="question.key + '-county'"
            class="form__input"
            type="text"
            placeholder="County"
            formControlName="county"
            autocomplete="off"
          />
        </div>

        <div>
          <app-dynamic-validation
            [form]="form"
            [key]="question.key + '.county'"
            [label]="'County'"
          ></app-dynamic-validation>
        </div>
      </div>
      <div>
        <div>
          <label [attr.for]="question.key + '-postcode'">Postcode</label>
        </div>
        <div>
          <input
            [attr.id]="question.key + '-postcode'"
            class="form__input"
            type="text"
            placeholder="Postcode"
            formControlName="postcode"
            autocomplete="off"
          />
        </div>

        <div>
          <app-dynamic-validation
            [form]="form"
            [key]="question.key + '.postcode'"
            [label]="'Postcode'"
          ></app-dynamic-validation>
        </div>
      </div>
    </div>
    <div>
      <ng-container *ngIf="control && !invalidPostcode">
        <div *ngIf="control.invalid && (control.dirty || control.touched)">
          <div
            [attr.id]="'error-' + question.key"
            class="errorMessage NW20-errorIsaMessage"
          >
            <p class="errorMessageContent">Address is required</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
