import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './components/home/home.component';
import { DeathClaimDocumentsComponent } from './components/documents/death-claim-documents/death-claim-documents.component';
import { EchoComponent } from './echo/echo.component';


const routes: Routes = [
  /* Changes start here. */
  {
    path: 'profile',
    component: ProfileComponent,
    // The profile component is protected with MSAL Guard.
    canActivate: [MsalGuard]
  },
  {
    // The home component allows anonymous access
    path: '',
    component: HomeComponent
  },
  {
    // The home component allows anonymous access
    path: 'documents/:id',
    component: DeathClaimDocumentsComponent,
    canActivate: [MsalGuard]
  },
  {
    // The home component allows anonymous access
    path: 'echo',
    component: EchoComponent,
    canActivate: [MsalGuard]
  },  
];

@NgModule({
  /* Changes start here. */
  // Replace the following line with the next one
  //imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {
  })],
  /* Changes end here. */
  exports: [RouterModule]
})
export class AppRoutingModule { }