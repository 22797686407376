<ng-template #completedState>
  <svg
    class="completed steps-icon steps-icon--selected"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.6045" cy="11.9496" r="11.75" />
    <path
      d="M17.4368 8.19958L11.0202 14.6163L8.10352 11.6996"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<div class="steps sf-card" *ngIf="steps">
  <ul class="steps__list">
    <ng-container *ngIf="currentStep$ | async as currentStep">
      <ng-container
        *ngFor="let step of steps; let i = index; let last = last"
        class="step"
      >
        <li class="steps__list-item">
          <ng-template
            [ngIf]="step.page === currentStep || step.page > currentStep"
            [ngIfElse]="completedState"
          >
            <svg
              class="steps-icon"
              [ngClass]="{
                'steps-icon--selected': step.page === currentStep
              }"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12.166" cy="11.75" r="11.75" />
              <path [attr.d]="svgNumberPath[i]" fill="#13243A" />
            </svg>
          </ng-template>
          <p
            class="steps__list-item-content"
            [ngClass]="{
              'steps__list-item-content--selected': step.page === currentStep,
              'steps__list-item-content--completed': step.page < currentStep
            }"
          >
            {{ step.title }}
          </p>
        </li>
        <hr *ngIf="!last" class="steps__list-item-line" />
      </ng-container>
    </ng-container>
  </ul>
</div>
