
<ng-container [ngSwitch]="question.type">

    <ng-container *ngSwitchCase="'multi'">
        <app-dynamic-multiselect-dropdown [question]="question" [form]="form"></app-dynamic-multiselect-dropdown>
    </ng-container>

    <ng-container *ngSwitchDefault [ngTemplateOutlet]="default"></ng-container>
</ng-container>

<ng-template #default>
    <div [formGroup]="form" [attr.id]="'form-' + question.key">
        <select [attr.id]="question.key" class="form__input form__input--dropdown" [formControlName]="question.key">
            <option value="" disabled selected>{{dropdownTitle}}</option>
            <option *ngFor="let option of question.options" [value]="option.value">{{option.key}}</option>
        </select>
    </div>
</ng-template>