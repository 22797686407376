import { Component, OnInit, Input } from '@angular/core';
import { IDynamicFormfield } from '../../models/dynamic-formfield.interface';
import { CustomFormGroup } from '../../models/form/custom-form-group.model';
import { DependenciesService} from './../../services/dependencies.service'
import { QuestionDropdown } from '../../models/questions/question-dropdown.model';

@Component({
	selector: 'app-dynamic-dropdown',
	templateUrl: 'dynamic-dropdown.component.html',
	styleUrls: ['dynamic-dropdown.component.css']
})
export class DynamicDropdownComponent implements OnInit, IDynamicFormfield {
	@Input() question: QuestionDropdown;
	@Input() form: CustomFormGroup;

	dropdownTitle: string;

	constructor(private dependenciesService: DependenciesService) { }

	ngOnInit() {
		if (this.question.options[0] !== undefined) {
			if (this.question.options[0].value === 'disabled') {
				this.dropdownTitle = this.question.options[0].key;
				this.question.options.splice(0, 1);
			}
		}
	}

	ngAfterViewInit() {
		//After the component view initialize has completed, broadcast so that any dependencies are triggered 
		this.dependenciesService.broadcast(this, this.form.controls[this.question.key].value);
	}
}
