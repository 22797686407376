import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';

const dynamicFormsRoutes: Routes = [
	{ path: 'form/:formName/:formType', component: DynamicFormComponent },
	{ path: 'form/:formName/:formType/:id', component: DynamicFormComponent },
];
@NgModule({
	imports: [
		RouterModule.forChild(dynamicFormsRoutes)
	],
	exports: [
		RouterModule
	]
})
export class DynamicFormsRoutingModule { }
