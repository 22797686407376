import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgxFileDropEntry } from "ngx-file-drop";
import { EMPTY, Subject, catchError, map, of, switchMap } from "rxjs";
import { DeathClaimsService } from "src/app/services/death-claims.service";

@Component({
	selector: 'app-document-upload',
	templateUrl: './document-upload.component.html',
	styleUrls: ['./document-upload.component.css']
})
export class DocumentUploadComponent  {
	@Input() claimId: string;
	@Input() documentId: string;
	@Output() onUploaded: EventEmitter<boolean> = new EventEmitter();

	private handleDocumentUpload: Subject<File> = new Subject();

	public errorMessage = "";

	public documentUploadResponse$ = this.handleDocumentUpload.pipe(
		switchMap((file: File) => this.deathClaimsService.uploadDocument(this.claimId, this.documentId, file)),
		map((response: boolean) =>  {
			if (response) {
				this.onUploaded.next(true);
			}

			return response;
		}),
		catchError((error: string) => {
			this.errorMessage = error;
			return EMPTY;
		  })
	);

	public file: NgxFileDropEntry;
	public acceptedFormats: string = ".PDF, .JPEG, .JPG, .PNG, .HEIC";

	constructor(private deathClaimsService: DeathClaimsService) { }

	public dropped(droppedFiles: NgxFileDropEntry[]) {
		if (droppedFiles.length > 1) {
			return;
		}

		let droppedFile = droppedFiles[0];
		this.file = droppedFile;

		if (droppedFile.fileEntry.isFile) {
			const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
			fileEntry.file((file: File) => {
				if (file.size > 20000000)
				{
					this.errorMessage = "File size too large";
					return;
				}
				this.handleDocumentUpload.next(file);
			});
		}
	}
}
