<div [formGroup]="form" [attr.id]="'form-' + question.key">
	<div [ngSwitch]="question.type">
		<div *ngSwitchCase="'tele'" class="NW20-greyBtnsWrapper">
			<div *ngFor="let option of question.options" class="NW20-greyBtnsItem">
				<!--<div class="NW20-greyBtnsContainer">-->
				<div class="NW20-greyBtnsContainer">
					<a [ngClass]="{'btn': true, 'btnSelected': selected(option.value)}" (click)="updateValue(option.value)">{{option.key}}</a>
				</div>
			</div>
		</div>
		<div *ngSwitchCase="'readonly'" class="NW2-radioBtnsContainer">
			<div *ngFor="let option of question.options; let i=index" class="NW20-radioBtn">
				<fieldset [disabled]="true">
					<input type="radio" [attr.id]="question.key + '-' + option.key" [formControlName]="question.key" [attr.name]="question.key" [value]="option.value" (change)="updateValue(option.value)"
						[checked]="preValue === option.value.toString()" autocomplete="off" />
					<label [attr.for]="question.key + '-' + option.key">{{option.key}}</label>
				</fieldset>
			</div>
		</div>
		<div *ngSwitchCase="'multiple'">
			<div *ngFor="let option of question.options; let i=index" class="multiple">
				<input type="radio" [attr.id]="question.key + '-' + option.key" [formControlName]="question.key" [attr.name]="question.key" [value]="option.value" (change)="updateValue(option.value)"
					[checked]="preValue === option.value.toString()" autocomplete="off" />
				<label [attr.for]="question.key + '-' + option.key">{{option.key}}</label>
			</div>
		</div>
		<div *ngSwitchDefault class="NW2-radioBtnsContainer">
			<div *ngFor="let option of question.options; let i=index" class="NW20-radioBtn">
				<input type="radio" [attr.id]="question.key + '-' + option.key" [formControlName]="question.key" [attr.name]="question.key" [value]="option.value" (change)="updateValue(option.value)"
					[checked]="preValue === option.value.toString()" autocomplete="off" />
				<label [attr.for]="question.key + '-' + option.key">{{option.key}}</label>
			</div>
		</div>
	</div>
</div>