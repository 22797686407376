import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { QuestionBase, QuestionDependency } from './../models/questions/question-base.model';
import { CustomFormGroup } from './../models/form/custom-form-group.model';
import { KeyValue } from '@angular/common';
import { CustomFormValidators } from '../validators/CustomFormValidators';

@Injectable()
export class QuestionControlService {
	private group: any = {};

	constructor(private formBuilder: FormBuilder) { }

	formGroup(questions: QuestionBase<any>[]) {
		return this.LoopQuestions(questions);
	}

	// Create FormControls for each control supplied
	// unless controlType is in switch statement
	// which means control should be a FormGroup
	LoopQuestions(questions: QuestionBase<any>[]) {
		questions.forEach(question => {
			
			let validators = this.addValidators(question);

			switch (question.controlType) {

				case "group":
					this.LoopQuestions(question.children!);
					break;

				case "table":
					question.table?.tRows.forEach(row => {
						this.LoopQuestions(row.columns);
					});
					break;
				
				case "repeater":
					this.group[question.key] = new FormControl(question.value, validators);
					const fullQuestionSet = this.generateRepeaterQuestionSets(question);

					if (fullQuestionSet) {
						//assigning the full generated question set back to the base question for use in components
						question.children = fullQuestionSet;
						this.LoopQuestions(question.children);
					}
					break;
					
				case 'address':
					this.group[question.key] = this.formBuilder.group({
						line1: new FormControl('', validators),
						line2: new FormControl(''),
						city: new FormControl(''),
						county: new FormControl(''),
						postcode: new FormControl('',validators)
					});
					break;

				case 'bmi':
					this.group[question.key] = this.formBuilder.group({
						heightMetric: '',
						weightMetric: '',
						heightImperialFeet: '',
						heightImperialInches: '',
						weightImperialStone: '',
						weightImperialPounds: ''
					});
					break;

				case 'directdebit':
					this.group[question.key] = this.formBuilder.group({
						ddNameOfAccountHolder: '',
						ddSortCode: '',
						ddAccountNumber: '',
						ddBankAccountHeldInPayersName: '',
						ddAllowedFromAccount: '',
						ddCollectionDate: ''
					});
					break;


				case 'declaration':
					this.group[question.key] = this.formBuilder.group({
						email: false,
						text: false,
						post: false,
						phone: false
					});
					break;

				case 'plan-length':
					this.group['PlanLengthOption'] = new FormControl(question.value, validators);
					break;

				case 'claims-summary':
					this.group['SeeMedicalReportBeforeShepherds'] =  false;
					this.group['ReadMedicalReportsConfirmation'] = false;
					this.group['RelevantDeclarationConfirmation'] = false;
					this.group['InformationAuthorisationConfirmation'] = false;
					this.group['FinalDeclarationConfirmation'] = false;
					break;


				default:
					this.group[question.key] = new FormControl(question.value, validators);
					break;

			}
			if (this.group[question.key]) {
				this.group[question.key].step = question.step;
			}
		});

		return new CustomFormGroup(this.group);
	}

	addValidators(question: QuestionBase<any>) : ((control: AbstractControl<any, any>) => ValidationErrors | null)[] | null {

		let validators:any[] = [];

		if (!question.validators) {
			return validators
		}

		question.validators.forEach((validator: KeyValue<string,string>) => {
			switch (validator.key.toLowerCase()) {
				case "required":
					validators.push(Validators.required);
					break;

				case "minlength":
					validators.push(Validators.minLength(Number(validator.value)));
					break;

				case "maxlength":
					validators.push(Validators.maxLength(Number(validator.value)));
					break;

				case "min":
					validators.push(Validators.min(Number(validator.value)));
					break;
				
				case "max":
					validators.push(Validators.max(Number(validator.value)));
					break;

				case "pattern":
					validators.push(Validators.pattern(validator.value));
					break;
				case "email":
					validators.push(Validators.email);
					break;

				case "requiredtrue":
					validators.push(Validators.requiredTrue);
					break;
				case "validdate":
					validators.push(CustomFormValidators.validDate());
					break;					
				case "futuredate":
					validators.push(CustomFormValidators.futureDate());
					break;
				case "notfuturedate":
					validators.push(CustomFormValidators.notFutureDate());
					break;
				case "validsurvivingmember":
					validators.push(CustomFormValidators.validSurvivingMember());
					break;
			}
		});

		return validators;
	}

	generateRepeaterQuestionSets(parentQuestion: QuestionBase<any>) {
		if (!parentQuestion.children) {
			return null;
		}

		var repeaterViewModel = parentQuestion.value;
		let repeatAmountString = parentQuestion.options.find(x => x.key === 'repeatAmount')?.value;
		var repeatAmount =  Number(repeatAmountString) ?? 0;

		let fullQuestionSet = [];

		// create and adapt each question set 
		for (let index = 0; index < repeatAmount; index++) {
			
			// generating a new question set
			let questionSet = parentQuestion.children.map((question: QuestionBase<any>) => {
				return Object.assign({}, question)
			});

			questionSet.map((question: QuestionBase<any>) => {
				// update the key of each child question with an index
				question.key = question.key + index;
				// order doesn't need to be 0 indexed. adding 1
				question.order = index + 1;

				if (repeaterViewModel && Object.hasOwn(repeaterViewModel, question.key)) {
					// mapping the model value attached to the repeater
					question.value = repeaterViewModel[question.key];
				}

				// generating a new dependency set
				let dependencySet = question.dependencies?.map((dependency: QuestionDependency) => {
					return Object.assign({}, dependency)
				});


				//update the key of each dependency with an index
				dependencySet?.map((dependency:QuestionDependency) => {
					let dependencyGroup = dependency.group.map(keyvalue => Object.assign({}, keyvalue));

					dependencyGroup.map(keyvalue => {
						return keyvalue.key = keyvalue.key + index;
					});

					dependency.group = dependencyGroup;
					return dependency;
				});


				question.dependencies = dependencySet;

				if (question.dependencies == null)
				{
					question.dependencies = [];
				}

				question.dependencies.push(...AddRepeaterItemDependencySet());

				function AddRepeaterItemDependencySet() : QuestionDependency[]
				{
					let dependencySet = parentQuestion.dependencies?.map((dependency: QuestionDependency) => {
						return Object.assign({}, dependency)
					});
	
					dependencySet?.map((dependency:QuestionDependency) => {
						dependency.type = "repeater-item";
						let dependencyGroup = dependency.group.map(keyvalue => Object.assign({}, keyvalue));
						dependencyGroup.map(keyvalue => {
							return keyvalue.value = (index+1).toString();
						});
	
						dependency.group = dependencyGroup;
						return dependency;
					});

					if (dependencySet != null)
						return dependencySet;

					else 
						return [];
				}

			
			});

			fullQuestionSet.push(...questionSet);
		}

		return fullQuestionSet;
	}
}
