import { Component, Input, OnInit } from '@angular/core';
import { MultiTextModel } from '../../models/form/multi-text.model';

@Component({
  selector: 'app-dynamic-multi-text',
  templateUrl: './dynamic-multi-text.component.html',
	styleUrls: ['./dynamic-multi-text.component.css']
})
export class DynamicMultiTextComponent implements OnInit {
	@Input() content: MultiTextModel[];
  @Input() additionalStyles: any = {};

  hasContent: boolean;

  ngOnInit() {
    this.hasContent = this.content && this.content.length > 0;
  }

}
