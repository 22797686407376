import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {  BehaviorSubject, EMPTY, Observable, catchError, map, mergeMap, switchMap, filter } from 'rxjs';
import { Document } from 'src/app/models/document.model';
import { DeathClaimsService } from 'src/app/services/death-claims.service';

@Component({
  selector: 'app-death-claim-documents',
  templateUrl: './death-claim-documents.component.html',
  styleUrls: ['./death-claim-documents.component.css']
})
export class DeathClaimDocumentsComponent {
 
  constructor(
    private deathClaimsService: DeathClaimsService,
    private route: ActivatedRoute
  ) {}

  public loadDocuments: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public loadDocuments$: Observable<boolean> = this.loadDocuments.asObservable();
  public errorMessage = ''

  documentIconUrl: string = "https://shepherdsfriendly.blob.core.windows.net/image-media/icon-pdf.svg";

  claimId$: Observable<string> = this.route.params.pipe(
    map((params: Params) => params['id'])
  );

  documents$: Observable<Document[] | null> = this.loadDocuments$.pipe(
    mergeMap(() => this.claimId$),
    switchMap((claimId: string) => 
      this.deathClaimsService.getDocuments(claimId).pipe(filter(x => x != null), map(x => x!),
        map((documents: Document[]) => documents.filter((document: Document) => document.verifiedDetail == null)))
    ),
    catchError((error: string) => {
      this.errorMessage = error;
      return EMPTY;
    })
  );

  rejectedDocuments$: Observable<Document[] | null> = this.loadDocuments$.pipe(
    mergeMap(() => this.claimId$),
    switchMap((claimId: string) => 
      this.deathClaimsService.getDocuments(claimId).pipe(
        filter(x => x != null), map(x => x!),
        map((documents: Document[]) => documents.filter((document: Document) => document.verifiedDetail != null)))
    ),
    catchError((error: string) => {
      this.errorMessage = error;
      return EMPTY;
    })
  );

}
